import { FC, useEffect, useRef, useState } from "react";

import { Divider } from "@mui/material";

import classNames from "classnames";
import { Button } from "component-library";

import {
  AssetEconomicsChartProps,
  IChartData,
} from "../../../../types/charts/chartType/chartType";
import { Modules } from "../../../../types/modularity/modules";

import { CHART_MODE_VIEW } from "../../../../constants/charts/chartModeView";
import { MAX_WELLS_COUNT_ASSET_ECONOMICS } from "../../../../constants/charts/charts";

import useChartStore from "../../../../store/chart/chartStore";
import useAlertPopupStore from "../../../../store/map/alert/alertStore";
import useMapSelectionStore from "../../../../store/map/selection/mapSelectionStore";
import useModularityStore from "../../../../store/modularity/modularityStore";

import useAnalysisChartRefetch from "../../../../customHooks/charts/useAnalysisChartRefetch";
import useWellEconomicsAggregation from "../../../../customHooks/charts/useWellEconomicsAggregation";
import useWellEconomicsOutputData from "../../../../customHooks/panels/wellPanel/useWellEconomicsData";

import { clone } from "../../../../utils/helper";

import LoadingBackDrop from "../../../common/LoadingBackDrop";
import DownloadIcon from "../../../common/icons/DownloadIcon";
import WellPanelAccordion from "../../../panels/wellPanel/common/WellPanelAccordion";
import WellEconomicsOutput from "../../../panels/wellPanel/footer/WellEconomicsOutput";
import ChartTypeHeader from "../chartTypeHeader/ChartTypeHeader";
import AssetEconomicsMessageDisplay from "./AssetEconomicsMessageDisplay";
import AssetEconomicsViewOnly from "./AssetEconomicsViewOnly";

const AssetEconomics: FC<AssetEconomicsChartProps> = ({
  chartMode,
  chartId,
  chartType,
  onChartFullScreenClose,
}) => {
  const chartRef = useRef<any>();

  const [currentEconomicsIChartData, setCurrentEconomicsIChartData] = useState<
    IChartData | undefined
  >();

  const modules = useModularityStore((state) => state.modules);
  const updateModuleDataByKey = useModularityStore(
    (state) => state.updateModuleDataByKey
  );

  const chartData = useChartStore((state) => state.chartData);
  const getDataFlag = useChartStore((state) => state.chartDataRefetchFlag);
  const updateGetDataFlag = useChartStore(
    (state) => state.setChartDataRefetchFlag
  );
  const updateChartDataByKey = useChartStore(
    (state) => state.updateChartDataByKey
  );

  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  const selectedWellUwis = useMapSelectionStore(
    (state) => state.selectedWellUwis
  );

  const { refetch, updateRefetch } = useAnalysisChartRefetch({
    chartMode,
    chartId,
    chartType,
  });

  const {
    data: economicsData,
    loading: isLoading,
    loadingExport,
    getWellEconomicsOutputData,
  } = useWellEconomicsOutputData();

  const {
    warningMessage,
    isExpandedAccordion,
    setTriggerWarning,
    handleOnExpandDetails,
    handleSectionHeightUpdate,
  } = useWellEconomicsAggregation(chartId, chartMode);

  const updateModuleRefetch = () => {
    const chartModule: Modules | undefined = (clone(modules) as Modules[]).find(
      (module) => module.module === chartId
    );

    if (chartModule) {
      chartModule.refetch = false;

      updateModuleDataByKey(chartId, chartModule);
    }

    updateRefetch(false);
  };

  const handleExport = () => {
    getWellEconomicsOutputData({
      uwi: selectedWellUwis,
      auditVerbosity: "FullList",
      summaryOnly: false,
      isForAnalysisExport: true,
    });
  };

  useEffect(() => {
    if (!chartId) return;
    const chart = chartData?.find((data) => data.chartId === chartId) as
      | IChartData
      | undefined;

    if (chart) {
      setCurrentEconomicsIChartData(chart);
    }
  }, [chartData]);

  useEffect(() => {
    if (!selectedWellUwis.length && currentEconomicsIChartData) {
      const removedEconomicsData = {
        ...currentEconomicsIChartData,
        assetEconomicsRawData: undefined,
      };
      setCurrentEconomicsIChartData(removedEconomicsData);
      updateChartDataByKey(chartId, removedEconomicsData);
    }
  }, [selectedWellUwis]);

  useEffect(() => {
    if (!currentEconomicsIChartData?.assetEconomicsRawData) {
      const chart = chartData?.find((data) => data.chartId === chartId) as
        | IChartData
        | undefined;

      if (chart?.assetEconomicsRawData) setCurrentEconomicsIChartData(chart);
    }
  }, [currentEconomicsIChartData, chartData]);

  useEffect(() => {
    if (
      refetch &&
      selectedWellUwis.length &&
      selectedWellUwis.length < MAX_WELLS_COUNT_ASSET_ECONOMICS
    ) {
      getWellEconomicsOutputData({
        uwi: selectedWellUwis,
        auditVerbosity: "FullList",
        summaryOnly: true,
      });
    }
  }, [refetch]);

  useEffect(() => {
    if (!economicsData) {
      setTriggerWarning(undefined);
      return;
    }

    if (!selectedWellUwis.length) {
      updateModuleRefetch();
      return;
    }

    if (!economicsData.Result.Success) {
      updateAlertState({
        severity: "error",
        message: "No economics data available for selected wells.",
      });

      setTriggerWarning("No economics data available");
      updateModuleRefetch();
      return;
    }

    if (currentEconomicsIChartData) {
      const updatedIchartData: IChartData = {
        ...currentEconomicsIChartData,
        assetEconomicsRawData: {
          defaultValues: economicsData,
          recalculatedValues: null,
          userValuesRecalculated: null,
        },
      };

      updateChartDataByKey(chartId, updatedIchartData);
    }

    updateModuleRefetch();

    if (getDataFlag.refetch) {
      updateGetDataFlag({ chartId: "", refetch: false });
    }
  }, [economicsData]);

  useEffect(() => {
    handleSectionHeightUpdate(isLoading);
  }, [isLoading, warningMessage]);

  return (
    <div
      className={classNames({
        "economics-fullScreen-individual":
          chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART,
      })}
    >
      {!refetch && !isLoading ? (
        <>
          <ChartTypeHeader
            chartId={chartId}
            isChartExport={false}
            chartMode={chartMode}
            onChartFullScreenClose={onChartFullScreenClose}
            chartRendered={false}
            chartRef={chartRef}
            onChartExportData={handleExport}
          />
          <div
            className="plot-chart-container asset-economics-container-accordion"
            id={chartId}
            ref={chartRef}
          >
            {warningMessage ? (
              <AssetEconomicsMessageDisplay message={warningMessage} />
            ) : (
              <>
                {currentEconomicsIChartData?.assetEconomicsRawData && (
                  <>
                    <WellPanelAccordion
                      expanded={isExpandedAccordion}
                      header={"Details"}
                      onChange={() =>
                        handleOnExpandDetails(!isExpandedAccordion)
                      }
                      isLoading={false}
                      className="cl-custom-accordion"
                    >
                      <AssetEconomicsViewOnly
                        wellEconomicsData={{
                          defaultValues:
                            currentEconomicsIChartData.assetEconomicsRawData
                              .defaultValues,
                          recalculatedValues: null,
                          userValuesRecalculated: null,
                        }}
                      />
                      <Divider />
                      <div className="economics-details-footer">
                        <Button
                          text={
                            <>
                              <span>
                                <DownloadIcon />
                              </span>
                              <p>EXPORT</p>
                            </>
                          }
                          disabled={loadingExport}
                          onClick={handleExport}
                        />
                      </div>
                    </WellPanelAccordion>
                    <WellEconomicsOutput
                      wellEconomicsData={{
                        defaultValues:
                          currentEconomicsIChartData.assetEconomicsRawData
                            .defaultValues,
                        recalculatedValues: null,
                        userValuesRecalculated: null,
                      }}
                      isLoading={false}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <LoadingBackDrop
          className={classNames("plot-chart-area loader")}
          isOpen
        />
      )}
    </div>
  );
};

export default AssetEconomics;
