import { useCallback } from "react";

import { CHART_TYPES } from "../../constants/charts/charts";

import useModularityStore from "../../store/modularity/modularityStore";

export const useChartModule = () => {
  const modules = useModularityStore((state) => state.modules);
  const updateModuleDataRefresh = useModularityStore(
    (state) => state.updateModuleDataRefresh
  );

  const handleWellChartUpdate = useCallback(() => {
    const chartIdList = modules
      .filter(
        (module) =>
          module.chartType === CHART_TYPES.PRODUCTION_PLOT ||
          module.chartType === CHART_TYPES.TYPE_CURVE ||
          module.chartType === CHART_TYPES.ASSET_ECONOMICS ||
          module.chartType === CHART_TYPES.CASH_FLOW
      )
      .map((filtered) => filtered.module);

    if (chartIdList.length) updateModuleDataRefresh(chartIdList);
  }, [modules]);

  const handleWellPermitChartUpdate = useCallback(() => {
    const chartIdList = modules
      .filter((module) => module.chartType === CHART_TYPES.SCATTER_PLOT)
      .map((filtered) => filtered.module);

    if (chartIdList.length) updateModuleDataRefresh(chartIdList);
  }, [modules]);

  return { handleWellChartUpdate, handleWellPermitChartUpdate };
};
