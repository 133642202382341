import { create } from "zustand";

import { WellSelectionStates } from "../../../types/map/selection/store";

import { gridWellSelectionSlice } from "./gridWellSelectionSlice";
import { mapWellSelectionSlice } from "./mapWellSelectionSlice";

const useMapSelectionStore = create<WellSelectionStates>()((...a) => ({
  ...mapWellSelectionSlice(...a),
  ...gridWellSelectionSlice(...a),
}));

export default useMapSelectionStore;
