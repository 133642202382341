import { WellData } from "../../../types/common/wells";
import {
  SelectedKeys,
  SelectionStates,
} from "../../../types/map/selection/store";
import {
  DrawToSelectCallback,
  MapWellSelection,
} from "../../../types/map/selection/wellSelection";

import {
  DRAW_TO_SELECT_MAX_ALERT_MESSAGE,
  DRAW_TO_SELECT_MAX_SELECTION,
  DYNAMIC_BOTTOM_PERMIT_SPOTS,
  DYNAMIC_BOTTOM_WELL_SPOTS,
  DYNAMIC_PERMIT_SPOTS,
  DYNAMIC_WELL_SPOTS,
  PERMIT_SPOTS,
  WELL_PATHS,
  WELL_SPOTS,
  WELL_STICKS,
} from "../../../constants/constants";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/queryBuilder/attributeValues";

export const mapWellSelection = async ({
  info,
  addSelectedMapParentWellID,
  addSelectedBottomWellboreID,
  addMapSelectedPermitIDs,
  toggleLayer,
  updateWellSelectTrigger,
}: MapWellSelection) => {
  try {
    const { ParentWellID, BottomWellboreID, PermitId } = info.object.properties;

    if (
      info.layer?.props.id === WELL_SPOTS ||
      info.layer?.props.id === DYNAMIC_WELL_SPOTS
    ) {
      addSelectedMapParentWellID(ParentWellID);
    } else if (
      info.layer?.props.id === DYNAMIC_BOTTOM_WELL_SPOTS ||
      info.layer?.props.id === WELL_PATHS ||
      info.layer?.props.id === WELL_STICKS
    ) {
      addSelectedBottomWellboreID(BottomWellboreID);

      if (
        info.layer?.props.id === WELL_PATHS ||
        info.layer?.props.id === WELL_STICKS
      ) {
        toggleLayer(WELL_SPOTS, true);
        toggleLayer(DYNAMIC_BOTTOM_WELL_SPOTS, true);
      }
    } else if (
      info.layer?.props.id === PERMIT_SPOTS ||
      info.layer?.props.id === DYNAMIC_PERMIT_SPOTS ||
      info.layer?.props.id === DYNAMIC_BOTTOM_PERMIT_SPOTS
    ) {
      const PermitIDs: SelectedKeys = {};
      PermitIDs["P" + PermitId] = PermitId;
      addMapSelectedPermitIDs(PermitIDs);
    }

    updateWellSelectTrigger("map");
  } catch (error) {
    console.debug("mapWellSelection error", error);
  }
};

export const drawToSelectDataCallback = async ({
  drawToSelectData,
  addSelectedMapParentWellIDs,
  addMapSelectedPermitIDs,
  updateWellSelectTrigger,
  updateAlertState,
}: DrawToSelectCallback) => {
  try {
    if (drawToSelectData.length > DRAW_TO_SELECT_MAX_SELECTION) {
      updateAlertState({
        severity: "error",
        message: DRAW_TO_SELECT_MAX_ALERT_MESSAGE,
      });
    } else {
      const PWIDS: SelectedKeys = {};
      const PermitIDs: SelectedKeys = {};
      drawToSelectData.forEach((data) => {
        switch (data.RecordType) {
          case RECORD_TYPES.WELL:
            if (data.WellID) {
              PWIDS[data.ParentWellID] = data.WellID;
            }
            break;
          case RECORD_TYPES.PERMIT:
            if (data.PermitID) {
              PermitIDs["P" + data.PermitID] = data.PermitID;
            }
            break;
        }
      });

      if (PWIDS) addSelectedMapParentWellIDs(PWIDS);
      if (PermitIDs) addMapSelectedPermitIDs(PermitIDs);

      updateWellSelectTrigger("draw");
    }
  } catch (error) {
    console.debug("mapWellSelection error", error);
  }
};

export const getUpdatedSelectedStates = (
  state: SelectionStates,
  wells: WellData[]
) => {
  const newStates = {
    selectedMapParentWellIDs: { ...state.selectedMapParentWellIDs },
    selectedBottomWellboreIDs: { ...state.selectedBottomWellboreIDs },
    selectedWellIds: { ...state.selectedWellIds },
    selectedWellData: { ...state.selectedWellData },
    allSelectedGridData: { ...state.allSelectedGridData },
    selectedWellIdsKeys: [...state.selectedWellIdsKeys],
    selectedWellUwis: [...state.selectedWellUwis],
  };

  wells.forEach((well) => {
    const {
      UWI,
      WellID,
      ParentWellID,
      BottomWellboreID,
      PermitID,
      WellHeaderPermitKey,
      RecordType,
    } = well;
    const uwi = UWI ?? "";

    switch (RecordType) {
      case RECORD_TYPES.WELL:
        const wellData = {
          WellID,
          ParentWellID,
          BottomWellboreID,
          UWI: uwi,
          WellHeaderPermitKey,
          RecordType,
        };
        if (WellID) {
          newStates.selectedMapParentWellIDs[ParentWellID] = WellID;
          newStates.selectedBottomWellboreIDs[BottomWellboreID] = WellID;
          newStates.selectedWellIds[WellHeaderPermitKey] = WellID;
          newStates.selectedWellData[WellHeaderPermitKey] = wellData;
          newStates.allSelectedGridData[WellHeaderPermitKey] = WellID;

          if (!newStates.selectedWellIdsKeys.includes(WellID)) {
            newStates.selectedWellIdsKeys.push(WellID);
          }

          if (!newStates.selectedWellUwis.includes(uwi)) {
            newStates.selectedWellUwis.push(uwi);
          }
        }

        break;

      case RECORD_TYPES.PERMIT:
        //TODO: ADD STATED FOR PERMITS

        break;
    }
  });

  return newStates;
};
