import { FC } from "react";

import config from "../../configs/appSettings.js";

const LasViewerForm: FC<{ uwis: string[] }> = ({ uwis }) => {
  let access_token = "";
  const oktaLocalStorage = localStorage.getItem("okta-token-storage");

  if (oktaLocalStorage) {
    const { accessToken } = JSON.parse(
      localStorage.getItem("okta-token-storage") ?? "{}"
    );

    access_token = accessToken.accessToken;
  }

  return (
    <form
      id="lasWorxFormPost"
      action={config.R360LAS_URL}
      method="POST"
      target="_blank"
    >
      <div className="form-redirect-container">
        <input
          id="access_token"
          type="text"
          name="access_token"
          value={access_token}
        />
        <input
          id="sourceApp"
          type="text"
          name="sourceApp"
          value="WDA_LAS_WORX"
        />
        <textarea
          rows={1}
          cols={1}
          id="uwis"
          style={{ width: "170px", height: "176px" }}
          name="uwis"
          defaultValue={uwis.toString()}
        />
        <input type="submit" value="Submit" />
      </div>
    </form>
  );
};

export default LasViewerForm;
