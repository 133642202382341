import React, { FC, useMemo } from "react";

interface WellInfoTextProps {
  label: string;
  value?: string | boolean | number | Date | React.ReactNode;
  icon?: React.ReactNode;
}

const WellInfoText: FC<WellInfoTextProps> = ({ label, value, icon }) => {
  const valueDisplay = useMemo(() => {
    if (!value) return "";

    //if value is node/element return element as is
    return React.isValidElement(value) ? value : value.toString();
  }, [value]);

  return (
    <div className="well-label-grouped-info">
      {icon && <div className="well-info-icon">{icon}</div>}
      <div>
        <div className="well-info-label">{label}</div>
        <div className="well-info-value tgs-operator">{valueDisplay}</div>
      </div>
    </div>
  );
};

export default WellInfoText;
