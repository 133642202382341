import { useCallback, useEffect, useMemo } from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { useOktaAuth } from "@okta/okta-react";

import { View } from "ol";
import Map from "ol/Map";
import { ScaleLine } from "ol/control.js";
import { defaults as defaultInteractions } from "ol/interaction.js";
import { Group as LayerGroup } from "ol/layer";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

import { Deck, MapView, PickingInfo } from "deck.gl";

import config from "../../configs/appSettings";

import {
  BOTTOM_WELL_SPOTS,
  BUBBLE_MAP,
  DYNAMIC_VECTOR_TILES,
  MAP_SCALE_BAR,
  WELL_SPOTS,
} from "../../constants/constants";
import { DRAW_LAYER } from "../../constants/map/layers";
import {
  PERMIT_PANEL_RIGHT_CLICK_ACTION,
  WELL_PANEL_RIGHT_CLICK_ACTION,
} from "../../constants/panels/wellPanel/wellPanel";

import useChartStore from "../../store/chart/chartStore";
import useDataGridStore from "../../store/grid/dataGridStore";
import useMapHoverStore from "../../store/map/hover/mapHoverStore";
import useMapStore from "../../store/map/mapStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";
import useModularityStore from "../../store/modularity/modularityStore";
import usePanelsStore from "../../store/panels/panelsStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";
import useStore from "../../store/useStore";

import useAllWellData from "../../customHooks/grid/useAllWellData";
import useBubbleMapInfo from "../../customHooks/map/mapSettings/useBubbleMapInfo";
import useLayerStylingInfo from "../../customHooks/map/mapSettings/useLayerStylingInfo";
import useCartoClickProps from "../../customHooks/map/useCartoClickProps";
import useMap from "../../customHooks/map/useMap";
import useMapHover from "../../customHooks/map/useMapHover";
import useSagaMap from "../../customHooks/map/useSagaMap";
import { useSwitchRasterLayers } from "../../customHooks/map/useSwitchRasterLayers";
import useUpdateDVTLayers from "../../customHooks/map/useUpdateDVTLayers";
import useUpdateNonDVTLayers from "../../customHooks/map/useUpdateNonDVTLayers";
import useZoomToFitPadding from "../../customHooks/map/useZoomToFitPadding";
import { useModularity } from "../../customHooks/modularity/useModularity";
import usePermitPanelViewAction from "../../customHooks/panels/permitsPanel/usePermitPanelViewAction";
import useWellPanelViewAction from "../../customHooks/panels/wellPanel/useWellPanelViewAction";
import useSearchCriteria from "../../customHooks/search/useSearchCriteria";

import { cartoClickCallback } from "../../utils/map/layers/cartoClickCallback";
import { generateTGSBaseLayer } from "../../utils/map/layers/layerService";
import { getCurrentMapDVTLayers } from "../../utils/map/layers/layerUtils";

import { getSelectedLayerNames, processDynamicVectorTile } from "../../utils";
import Loading from "../common/Loading";
import MessageBar from "../common/MessageBar";
import R360 from "../export/R360";
import AnalysisChartToggle from "../panels/analysisChartsPanel/AnalysisChartToggle";
import AlertBoxes from "./AlertBoxes";
import MapDistinctionMenu from "./MapDistinctionMenu";
import MapHoverCardManager from "./MapHoverCardManager";
import useMiniMap from "./MiniMap";

import "../../assets/scss/map.scss";

const SagaMap = () => {
  const setMap = useMapStore((state) => state.setMap);
  const deckGl = useMapStore((state) => state.deckGl);
  const setDeckGl = useMapStore((state) => state.setDeckGl);
  const layers = useMapStore((state) => state.layers);
  const setDrawLayerSource = useMapStore((state) => state.setDrawLayerSource);
  const currentZoom = useMapStore((state) => state.currentZoom);
  const DVTUpdated = useMapStore((state) => state.DVTUpdated);
  const DVTQueryLoading = useMapStore((state) => state.DVTQueryLoading);
  const DVTQuery = useMapStore((state) => state.DVTQuery);
  const DVTQueryError = useMapStore((state) => state.DVTQueryError);
  const mapScaleBarUnit = useMapStore((state) => state.mapScaleBarUnit);

  const chartData = useChartStore((state) => state.chartData);

  const initialGridSearchMade = useDataGridStore(
    (state) => state.initialGridSearchMade
  );
  const allWellGridDataLoading = useDataGridStore(
    (state) => state.allWellGridDataLoading
  );
  const dataGridSelector = useDataGridStore((state) => state.allWellSelectors);
  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const showGrid = useDataGridStore((state) => state.showGrid);
  const gridHeight = useDataGridStore((state) => state.gridHeight);

  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );
  const isOpenAnalysisPanel = usePanelsStore(
    (state) => state.isOpenAnalysisPanel
  );

  const isLoadingSavedSearchFileUpload = useUWISearchStore(
    (state) => state.isLoadingSavedSearchFileUpload
  );

  const mapHoverData = useMapHoverStore((state) => state.mapHoverData);

  // Well Spot Styling
  const isProcessingStyles = useMapSettingsStore(
    (state) => state.isProcessingStyles
  );
  const isHighlightSelectedSpots = useMapSettingsStore(
    (state) => state.isHighlightSelectedSpots
  );
  const layerStyles = useMapSettingsStore((state) => state.layerStyles);
  const layerLegendColors = useMapSettingsStore(
    (state) => state.layerLegendColors
  );
  const compressedSurfaceData = useMapSettingsStore(
    (state) => state.compressedSurfaceData
  );
  const compressedBottomData = useMapSettingsStore(
    (state) => state.compressedBottomData
  );
  const compressedPermitData = useMapSettingsStore(
    (state) => state.compressedPermitData
  );
  const partitionMinMax = useMapSettingsStore((state) => state.partitionMinMax);
  const permitMinMax = useMapSettingsStore((state) => state.permitMinMax);

  // Bubble Map
  const compressedSurfaceBubbleMapData = useMapSettingsStore(
    (state) => state.compressedSurfaceBubbleMapData
  );
  const compressedBottomBubbleMapData = useMapSettingsStore(
    (state) => state.compressedBottomBubbleMapData
  );
  const bubbleMapPartitionMinMax = useMapSettingsStore(
    (state) => state.bubbleMapPartitionMinMax
  );

  // Loading states for DVT
  const DVTProcessing = useStore((state) => state.DVTProcessing);
  const updateDVTProcessing = useStore((state) => state.updateDVTProcessing);

  const updateViewportChanged = useStore(
    (state) => state.updateViewportChanged
  );

  const toastProps = useStore((state) => state.toastProps);

  const isLoadingDrawToSelect = useStore(
    (state) => state.isDrawToSelectLoading
  );

  const modules = useModularityStore((state) => state.modules);

  // Well Card
  const selectedWellCardPWIDs = usePanelsStore(
    (state) => state.selectedWellCardPWIDs
  );
  const selectedWellCardBWIDs = usePanelsStore(
    (state) => state.selectedWellCardBWIDs
  );

  //Permit Card
  const selectedCardPermitIDs = usePanelsStore(
    (state) => state.selectedCardPermitIDs
  );

  // Map - Parent Well IDs
  const selectedMapParentWellIDs = useMapSelectionStore(
    (state) => state.selectedMapParentWellIDs
  );

  // Map - UWI12s
  const selectedBottomWellboreIDs = useMapSelectionStore(
    (state) => state.selectedBottomWellboreIDs
  );

  const selectedPermitIds = useMapSelectionStore(
    (state) => state.selectedPermitIds
  );

  const selectedLayerNames: string[] = useMemo(
    () => getSelectedLayerNames(layers),
    [layers]
  );

  const { authState } = useOktaAuth();
  const { cartoClickProps } = useCartoClickProps();

  const { hasSearchCriteria } = useSearchCriteria();

  const { map, getDVTQuery } = useSagaMap();

  useUpdateNonDVTLayers(selectedLayerNames);
  const { removeLayers, addLayers } = useUpdateDVTLayers({
    getDVTQuery,
    cartoClickProps,
    selectedLayerNames,
  });

  useSwitchRasterLayers({ selectedLayerNames, removeLayers, addLayers, map });

  const { miniMap } = useMiniMap();
  const { hasMap } = useMap();
  const {
    zoomToFitLeftPadding,
    zoomToFitRightPadding,
    zoomToFitBottomPadding,
  } = useZoomToFitPadding();

  const { handleOpenWellCard } = useWellPanelViewAction();
  const { handleOpenPermitPanel } = usePermitPanelViewAction();
  const { isOpenDstPanel } = useModularity();

  useAllWellData();
  useMapHover();

  const isLayerSelected = useCallback(
    (layerName: string) => {
      return !!layers.find((layer) => layer.name === layerName)?.isSelected;
    },
    [layers]
  );

  const { getLayerStylingInfo } = useLayerStylingInfo();
  const { getBubbleMapInfo } = useBubbleMapInfo();

  const isMapScaleBarSelected = useMemo(
    () => !!isLayerSelected(MAP_SCALE_BAR),
    [isLayerSelected]
  );

  useEffect(() => {
    if (!map) return;

    map.getControls().forEach((control) => {
      if (control instanceof ScaleLine) map.removeControl(control);
    });

    if (isMapScaleBarSelected) {
      map.addControl(
        new ScaleLine({
          units: mapScaleBarUnit,
          bar: true,
          steps: 4,
          text: false,
          minWidth: 140,
        })
      );
    }

    const miniMapEl = document.getElementsByClassName(
      "mini-map"
    )[0] as HTMLElement;
    const mapScaleBarEl = document.getElementsByClassName(
      "ol-scale-bar"
    )[0] as HTMLElement;

    if (miniMapEl) {
      if (!showGrid && !showGridHeader) {
        miniMapEl.style.bottom = "84px";
      } else if (!showGrid && showGridHeader) {
        miniMapEl.style.bottom = "144px";
      } else {
        miniMapEl.style.bottom = `calc(${gridHeight} + 84px)`;
      }
    }

    if (mapScaleBarEl) {
      let mapScaleBarBottom = `calc(${gridHeight} + 30px)`;
      let mapScaleBarLeft = isOpenAppLevelNav ? "74px" : "10px";

      if (!showGrid && !showGridHeader) {
        mapScaleBarBottom = "30px";
      } else if (!showGrid && showGridHeader) {
        mapScaleBarBottom = "90px";
      }

      if (isOpenAppLevelNav && isOpenLeftSidePanel && isOpenDstPanel) {
        mapScaleBarLeft = `calc(${mapScaleBarLeft} + 700px)`;
      } else if (isOpenAppLevelNav && isOpenLeftSidePanel) {
        mapScaleBarLeft = `calc(${mapScaleBarLeft} + 400px)`;
      }

      mapScaleBarEl.style.left = mapScaleBarLeft;
      mapScaleBarEl.style.bottom = mapScaleBarBottom;
    }
  }, [
    map,
    gridHeight,
    showGrid,
    showGridHeader,
    isOpenAppLevelNav,
    isOpenLeftSidePanel,
    isMapScaleBarSelected,
    mapScaleBarUnit,
    isOpenDstPanel,
  ]);

  useEffect(() => {
    if (!authState?.accessToken?.accessToken) return;
    if (map) return;

    const deckGL = new Deck({
      initialViewState: {
        longitude: 0,
        latitude: 0,
        zoom: 1,
      },
      views: new MapView({
        repeat: true,
      }),
      getCursor: () => "inherit",
      controller: false,
      parent: document.getElementById("map") as HTMLDivElement,
      style: {
        pointerEvents: "none",
        zIndex: "1",
      },
      pickingRadius: 0,
      // For debugging the pickable area of spots
      // drawPickingColors: true,
    });

    setDeckGl(deckGL);
  }, [authState]);

  useEffect(() => {
    if (hasMap) return;
    const initialize = async () => {
      const baseLayer = generateTGSBaseLayer();

      const polygonSource = new VectorSource({ wrapX: false });
      setDrawLayerSource(polygonSource);
      // updateDrawLayerSource(polygonSource);
      const polygonLayer = new VectorLayer({
        source: polygonSource,
        zIndex: 700,
        properties: {
          title: DRAW_LAYER,
          isBaseMap: true,
          isVisible: true,
        },
      });
      const polygonLayerGroup = new LayerGroup({
        layers: [polygonLayer],
        properties: {
          title: DRAW_LAYER,
        },
        zIndex: 700,
      });

      if (miniMap) {
        const map = new Map({
          controls: [miniMap],
          target: "map",
          layers: [baseLayer, polygonLayerGroup],
          view: new View({
            center: config.defaultViewSettings.center,
            zoom: config.defaultViewSettings.zoom,
            constrainResolution: true,
            maxZoom: config.defaultViewSettings.maxZoom,
          }),
          interactions: defaultInteractions({
            onFocusOnly: false,
          }),
        });
        setMap(map);
      }
    };

    if (deckGl && miniMap) {
      initialize();
    }
  }, [deckGl, miniMap, hasMap]);

  // There are unnecessary calls of processDVT
  // when toggling layers and search criteria
  // since some processDVT deps have deps on layers and searchCriteria
  const processDVT = useCallback(
    (selectedLayersWithDVT: any) => {
      const showLayer = (layerName: string) => {
        if (layerName === BUBBLE_MAP) {
          return (
            isLayerSelected(layerName) &&
            (isLayerSelected(WELL_SPOTS) ||
              isLayerSelected(BOTTOM_WELL_SPOTS)) &&
            hasSearchCriteria &&
            currentZoom >= 12
          );
        }

        return (
          isLayerSelected(layerName) &&
          (currentZoom >= 12 ||
            DVTUpdated ||
            (!DVTUpdated && currentZoom >= 12))
        );
      };

      selectedLayersWithDVT.forEach((layerName: any) => {
        const DVTWellConfigs = config.layerDefinitions[layerName].layers.filter(
          (layerConfig: any) => layerConfig.fetchType === DYNAMIC_VECTOR_TILES
        );

        const { compressedWellSpotData, compressedWellSpotInfo } =
          getLayerStylingInfo(layerName);
        const { compressedBubbleMapData, compressedBubbleMapInfo } =
          getBubbleMapInfo();

        DVTWellConfigs.forEach((cartoWellConfig: any) => {
          if (!authState?.accessToken?.accessToken) return;
          processDynamicVectorTile({
            accessToken: authState?.accessToken?.accessToken,
            title: layerName,
            subtitle: cartoWellConfig.title,
            visible: showLayer(layerName),
            currentDeckGl: deckGl,
            linkToOpenlayersMap: false,
            connection: cartoWellConfig.connection,
            compressedWellSpotData,
            compressedWellSpotInfo,
            compressedBubbleMapData,
            compressedBubbleMapInfo,
            isProcessingStyles,
            isHighlightSelectedSpots,
            initialGridSearchMade,

            color: cartoWellConfig.defaultColor,
            lineColor: cartoWellConfig.defaultLineColor,
            size: cartoWellConfig.defaultSize,
            selectedMapParentWellIDs,
            selectedBottomWellboreIDs,
            selectedPermitIds,
            selectedWellCardPWIDs,
            selectedWellCardBWIDs,
            selectedCardPermitIDs,
            DVTQuery,
            zIndex: cartoWellConfig.zIndex,
            minZoom: cartoWellConfig.minZoom,
            geoColumn: cartoWellConfig.geoColumn,
            isBottomSelected: isLayerSelected(BOTTOM_WELL_SPOTS),
            dataGridSelector,
            onClick: !cartoWellConfig.clickDisabled
              ? (info: PickingInfo, event: any) =>
                  cartoClickCallback({
                    info,
                    event,
                    ...cartoClickProps,
                  })
              : undefined,

            layerStyles,
            layerLegendColors,
            zoom: currentZoom,

            updateDVTProcessing,
            updateViewportChanged,
          });
        });
      });
    },
    [
      deckGl,
      cartoClickProps,
      layerStyles,
      layerLegendColors,
      isProcessingStyles,
      isHighlightSelectedSpots,
      selectedMapParentWellIDs,
      selectedBottomWellboreIDs,
      selectedPermitIds,
      selectedWellCardPWIDs,
      selectedWellCardBWIDs,
      selectedCardPermitIDs,
      DVTQueryLoading,
      DVTQuery,
      initialGridSearchMade,
      updateDVTProcessing,
      updateViewportChanged,
      currentZoom,
      DVTUpdated,
      hasSearchCriteria,
      isLayerSelected,
      dataGridSelector,
      getLayerStylingInfo,
      getBubbleMapInfo,
    ]
  );

  useEffect(() => {
    if (!DVTProcessing && initialGridSearchMade) updateViewportChanged(false);
  }, [DVTProcessing, initialGridSearchMade, updateViewportChanged]);

  useEffect(() => {
    if (map && deckGl && (DVTUpdated || currentZoom >= 11) && !DVTQueryError) {
      // const layerNamesWithDVT = getLayerGroupNamesByFetchType(
      //   getLayerDefinitionsKeys(),
      //   DYNAMIC_VECTOR_TILES
      // );

      // const selectedLayersWithDVT = layerNamesWithDVT.filter((layerName) =>
      //   selectedLayerNames.includes(layerName)
      // );

      const layersWithDVT = getCurrentMapDVTLayers(map).map(
        (baseLayer) => baseLayer.getProperties().title
      );
      if (layersWithDVT.length && !isProcessingStyles) {
        processDVT(layersWithDVT);
      }
    }
  }, [
    cartoClickProps,
    // selectedLayerNames,
    deckGl,
    compressedSurfaceData,
    compressedBottomData,
    compressedPermitData,
    compressedSurfaceBubbleMapData,
    compressedBottomBubbleMapData,
    partitionMinMax,
    permitMinMax,
    bubbleMapPartitionMinMax,
    layerStyles,
    layerLegendColors,
    initialGridSearchMade,
    selectedMapParentWellIDs,
    selectedBottomWellboreIDs,
    selectedPermitIds,
    selectedWellCardPWIDs,
    selectedWellCardBWIDs,
    selectedCardPermitIDs,
    DVTQueryLoading,
    DVTQuery,
    DVTQueryError,
    currentZoom,
    DVTUpdated,
    isProcessingStyles,
    processDVT,
  ]);

  useEffect(() => {
    if (mapHoverData?.info) {
      if (mapHoverData.trigger === WELL_PANEL_RIGHT_CLICK_ACTION) {
        handleOpenWellCard();
      } else if (mapHoverData.trigger === PERMIT_PANEL_RIGHT_CLICK_ACTION) {
        handleOpenPermitPanel();
      }
    }
  }, [mapHoverData]);

  return (
    <div className="saga-map-container">
      {modules.length === 0 && chartData.length === 0 && (
        <div className="modules-menu-container">
          <AnalysisChartToggle />
        </div>
      )}
      {mapHoverData?.info && <MapHoverCardManager />}

      <MapDistinctionMenu />

      <div
        id="map"
        className={`map-container mini-map-padding-${
          isOpenAnalysisPanel && "pane-open"
        }`}
      >
        <MessageBar {...toastProps} />
        {initialGridSearchMade &&
          (DVTProcessing ||
            allWellGridDataLoading ||
            isLoadingDrawToSelect ||
            isProcessingStyles ||
            isLoadingSavedSearchFileUpload) && (
            <div
              className="map-overlay"
              style={{
                paddingBottom: zoomToFitBottomPadding,
                paddingRight: zoomToFitRightPadding,
                paddingLeft: zoomToFitLeftPadding,
              }}
            >
              <Loading />
            </div>
          )}
      </div>

      {<R360 />}
      {<AlertBoxes />}
    </div>
  );
};

export default SagaMap;
