import { ChartTypeProps } from "../../types/charts/chartType/chartType";

import { CHART_TYPES } from "../../constants/charts/charts";

import AssetEconomics from "./chartType/AssetEconomics/AssetEconomics";
import CashFlowChart from "./chartType/CashFlow/CashFlowChart";
import ProductionPlotChart from "./chartType/ProductionPlotChart";
import ScatterPlotChart from "./chartType/ScatterPlotChart";
import TypeCurveChart from "./chartType/TypeCurveChart";

const ChartType = ({ chartType, chartId, chartMode }: ChartTypeProps) => {
  return (
    <>
      {/* PRODUCTION PLOT */}
      {chartType === CHART_TYPES.PRODUCTION_PLOT && (
        <ProductionPlotChart
          chartType={chartType}
          chartId={chartId}
          chartMode={chartMode}
          onChartFullScreenClose={() => undefined}
        />
      )}
      {/* Type Curve */}
      {chartType === CHART_TYPES.TYPE_CURVE && (
        <TypeCurveChart
          chartType={chartType}
          chartId={chartId}
          chartMode={chartMode}
          onChartFullScreenClose={() => undefined}
        />
      )}
      {chartType === CHART_TYPES.SCATTER_PLOT && (
        <ScatterPlotChart
          chartType={chartType}
          chartId={chartId}
          chartMode={chartMode}
          onChartFullScreenClose={() => undefined}
        />
      )}
      {chartType === CHART_TYPES.ASSET_ECONOMICS && (
        <AssetEconomics
          chartType={chartType}
          chartId={chartId}
          chartMode={chartMode}
          onChartFullScreenClose={() => undefined}
        />
      )}
      {chartType === CHART_TYPES.CASH_FLOW && (
        <CashFlowChart
          chartType={chartType}
          chartId={chartId}
          chartMode={chartMode}
          onChartFullScreenClose={() => undefined}
        />
      )}
    </>
  );
};

export default ChartType;
