import * as ATTRIBUTE from "../../constants/attributes";

import { roundNumber } from "../helper";

/**
 * Formats a number based on the provided attribute key.
 * @param value - The number to be formatted.
 * @param attributeKey - The attribute key used to determine the formatting rules.
 * @returns The formatted number.
 */
export const formatNumberByAttribute = (
  value: number,
  attributeKey: string
) => {
  switch (attributeKey) {
    case ATTRIBUTE.LOE_COST.key:
    case ATTRIBUTE.GPT_COST.key:
    case ATTRIBUTE.WATER_COST.key:
      // Format number with 2 decimal places and commas
      return formatNumberWithNegative(value, 2, true);

    case ATTRIBUTE.BREAK_EVEN_PRICE.key:
    case ATTRIBUTE.PAYOUT.key:
      // Format number with 1 decimal place and commas
      return formatNumberWithNegative(value, 1, true);

    case ATTRIBUTE.FIXED_COST.key:
    case ATTRIBUTE.NET_PRESENT_VALUE.key:
      // Format number with no decimal places and commas
      return formatNumberWithNegative(value, 0, true);

    case ATTRIBUTE.DRILLING_COMPLETION_CAP_EX.key:
    case ATTRIBUTE.INFRA_CAP_EX.key:
      // Format number with 3 decimal places
      return formatNumberWithNegative(value, 3);

    case ATTRIBUTE.WORKING_INTEREST.key:
    case ATTRIBUTE.ROYALTY.key:
    case ATTRIBUTE.INTERNAL_RATE_OF_RETURN.key:
      // Format number with no decimal places
      return formatNumberWithNegative(value, 0);

    default:
      // Format number using default formatting rules
      return formatNumber(value);
  }
};

/**
 * Formats a negative number and its decimal places.
 * @param value - The number to be formatted.
 * @param numberOfDecimals - The number of decimal places to include in the formatted value.
 * @param hasCommas - Indicates whether the formatted value should include commas.
 * @returns The formatted number as a string.
 */
export const formatNumberWithNegative = (
  value: number,
  numberOfDecimals: number,
  hasCommas = false
) => {
  if (value === undefined || value === null) return value;

  const positiveValue = Math.abs(value);

  let formattedStringValue = formatNumberWithDecimals(
    positiveValue,
    numberOfDecimals,
    hasCommas
  );
  if (value < 0) formattedStringValue = `(${formattedStringValue})`;
  return formattedStringValue;
};

/**
 * Formats a number display according to global formatting standard.
 * This is a standard formatting applicable to MOST number attributes
 * @param val - The number to be formatted.
 * @param hasCommas - Indicates whether the formatted value should include commas.
 * @returns The formatted number or formatted number as a string.
 */
export const formatNumber = (val: number, hasCommas = true) => {
  if (val === undefined || val === null) return val;

  const truncateDecimalVal = Math.abs(Math.trunc(val));

  let formattedVal = val;

  if (truncateDecimalVal.toString().length >= 2) {
    formattedVal = roundNumber(formattedVal, 0);
  } else {
    formattedVal = roundNumber(formattedVal, 3);
  }

  return hasCommas ? getNumberWithComma(formattedVal) : formattedVal;
};

/**
 * Formats a number according to the specified number of decimal places
 * @param val - The number to be formatted.
 * @param decimalPlaces - The place where to round off a number to a whole number.
 * @param hasCommas - Indicates whether the formatted value should include commas.
 * @returns The formatted number or formatted number as a string.
 */
export const formatNumberWithDecimals = (
  val: number,
  decimalPlaces: number,
  hasCommas = true
) => {
  if (val === undefined || val === null) return val;

  const formattedVal = roundNumber(val, decimalPlaces);

  if (hasCommas) {
    return getNumberWithComma(formattedVal);
  }

  return formattedVal;
};

/**
 * Formats special cases of number display according to global formatting standard.
 * By default, round to the nearest 10th (e.g if 235 then output is 240 if 234 then output is 230)
 * This method will always return a whole number
 * @param val - The number to be formatted.
 * @param roundOffPlace - The place where to round off a number to a whole number.
 * @param hasCommas - Indicates whether the formatted value should include commas.
 * @returns The formatted number or formatted number as a string.
 */
export const formatSpecialNumber = (
  val: number,
  roundOffPlace = 10,
  hasCommas = true
) => {
  if (val === undefined || val === null) return val;

  const formattedVal = Math.round(val / roundOffPlace) * roundOffPlace;

  if (hasCommas) {
    return getNumberWithComma(formattedVal);
  }
  return formattedVal;
};

export const truncateStringDecimal = (val: string, decimalPlace: number) => {
  const values = val.split(".");
  if (values[1]) {
    return `${values[0]}.${values[1].substring(0, decimalPlace)}`;
  }

  return val;
};

export const getNumberWithComma = (val: number) => {
  const numberFormat = Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 21,
  });
  return numberFormat.format(val);
};

export const formatNumberInK = (number: number, division: number) => {
  return `${getNumberWithComma(Math.round(Math.abs(number) / division))}K`;
};

export const parseNumberData = (val: string | number) => {
  return typeof val === "string" ? parseFloat(val) : val;
};

const suffixes = new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"],
]);

export const formatNumberInOrdinal = (val: number) => {
  const ordinalNumber = new Intl.PluralRules("en-US", {
    type: "ordinal",
  });

  const rule = ordinalNumber.select(val);
  const suffix = suffixes.get(rule);
  return `${val}${suffix}`;
};
