import { Fragment } from "react";

import { ChevronRight, WarningAmber } from "@mui/icons-material";
import { Typography, styled } from "@mui/material";

const TextWithIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginBottom: 24,
  color: "#fff",
  fontSize: 14,
  fontWeight: 400,
});

const WarningIcon = styled(WarningAmber)({
  marginRight: 16,
  color: "#F57C00",
  fontSize: 20,
  cursor: "default",
});

const ChevronICon = styled(ChevronRight)({
  marginRight: 16,
  opacity: 0.7,
  fontSize: 20,
  cursor: "default",
});

const Description = styled(Typography)({
  color: "#fff",
  opacity: 0.7,
  fontSize: 14,
  // fontStyle: "italic",
  fontWeight: 400,
  marginBottom: 24,
});

const Image = styled("img")({
  width: "100%",
  height: 300,
  borderRadius: 4,
  marginBottom: 24,
});

const AccordionSubContent = styled("li")<{ isItalic?: boolean }>(
  ({ isItalic }) => ({
    marginLeft: 16,
    marginBottom: 10,
    fontSize: 14,
    fontStyle: isItalic ? "italic" : "normal",
    fontWeight: 400,
    color: "rgba(255, 255, 255, 0.70)",
  })
);

const SpanText = styled("span")({
  fontStyle: "normal",
});

const WhatsNext = () => (
  <Fragment>
    <TextWithIcon>Next Planned Release Date: 01/29/2025</TextWithIcon>
    <Description>
      Features currently in development for the next release include:
    </Description>
    <ul>
      <AccordionSubContent isItalic>
        <SpanText>Enhancements to Well Panel functionality</SpanText>
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        <SpanText>Water Fall Chart</SpanText>
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        <SpanText>Well and Permits Search Enhancements</SpanText>
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        <SpanText>Permit Pseudo Surveys</SpanText>
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        <SpanText>Appalachia Well Economics Data</SpanText>
      </AccordionSubContent>
    </ul>
  </Fragment>
);

export default WhatsNext;
