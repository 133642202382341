import { LayoutAxis } from "plotly.js";

import { ChartRange } from "../../../types/charts/chartType/chartType";

export const config = {
  responsive: true,
  displayModeBar: false,
  edits: {
    legendPosition: true,
  },
};
export const generalLayout = (isForFullScreenView?: boolean) => {
  return {
    autosize: true,
    paper_bgcolor: !isForFullScreenView ? "rgba(0,0,0,0)" : "#272727",
    plot_bgcolor: !isForFullScreenView ? "rgba(0,0,0,0)" : "#272727",
    showlegend: false,
  };
};

export const marginLayout = (isChartExport?: boolean) => {
  return {
    t: isChartExport ? 50 : 10,
    r: isChartExport ? 0 : 20,
    b: 0,
    l: isChartExport ? 70 : 60,
  };
};

export const fontLayout = {
  size: 12,
  family: "Roboto, Arial",
};

const isRangeValid = (currentValue: number | undefined | null) =>
  currentValue !== undefined && currentValue !== null;

export const xAxisLayout = (
  range: ChartRange | undefined,
  isForFullScreenView?: boolean
) => {
  const hasRange =
    range && range?.xRange && (range?.xRange as []).every(isRangeValid);
  const xAxis: Partial<LayoutAxis> = {
    zeroline: false,
    title: {
      text: "Minutes",
      font: {
        family: "Roboto, Arial",
        color: "rgba(255, 255, 255, 1)",
        size: isForFullScreenView ? 18 : 12,
      },
    },
    ...(isForFullScreenView && {
      tickfont: {
        size: 16,
      },
    }),
    automargin: true,
    color: "rgba(255, 255, 255, 0.7)",
    linecolor: "rgba(117, 117, 117, 1)",
    griddash: "dot",
    gridcolor: "rgba(117, 117, 117, 1)",
    hoverformat: ",.2f",
    showgrid: true,
    tickangle: 0,
    ...(hasRange
      ? {
          range: range.xRange,
          autorange: false,
        }
      : { autorange: true }),
  };
  return xAxis;
};

export const yAxisLayout = (
  range: ChartRange | undefined,
  isForFullScreenView?: boolean
) => {
  const hasRange =
    range && range?.yRange && (range?.yRange as []).every(isRangeValid);

  const yAxis: Partial<LayoutAxis> = {
    automargin: isForFullScreenView ? false : true,
    zeroline: false,
    color: "rgba(255, 255, 255, 0.7)",
    griddash: "dot",
    gridcolor: "rgba(117, 117, 117, 1)",
    linecolor: "rgba(117, 117, 117, 1)",
    showgrid: true,
    title: {
      text: "PSI",
      font: {
        family: "Roboto, Arial",
        color: "rgba(255, 255, 255, 1)",
        size: isForFullScreenView ? 18 : 12,
      },
    },
    ...(isForFullScreenView && {
      tickfont: {
        size: 16,
      },
    }),
    ...(hasRange
      ? {
          range: range.yRange,
          autorange: false,
        }
      : { autorange: true }),
  };

  return yAxis;
};
