import * as constant from "../../../constants/panels/wellPanel/wellEconomics";

const useWellEconomicsUoM = () => {
  const formatWellEconomicsUnits = (
    fieldKey: string,
    fieldDefaultUnit: string,
    targetUoM?: string
  ) => {
    let fieldUnit = fieldDefaultUnit;

    if (!targetUoM) return fieldUnit;

    if (targetUoM === constant.WELL_ECONOMICS_TARGET_UOM.MCFE) {
      switch (fieldKey) {
        case constant.WELL_ECONOMICS_LOE.key:
        case constant.WELL_ECONOMICS_GPT.key:
        case constant.WELL_ECONOMICS_TAX_PER_UNIT.key:
        case constant.WELL_ECONOMICS_BREAKEVEN_PRICE.key:
          fieldUnit = "$/mcfe";
          break;
      }
    }

    return fieldUnit;
  };
  return {
    formatWellEconomicsUnits,
  };
};

export default useWellEconomicsUoM;
