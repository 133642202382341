import { FC, useMemo } from "react";

import { WellEconomicsStore } from "../../../../types/panels/wellPanel/wellEconomicsType";

import * as constant from "../../../../constants/panels/wellPanel/wellEconomics";

import { convertNegativeToParenthesis } from "../../../../utils/panel/wellPanel/economics";

import WellInfoText from "../../../common/WellInfoText";
import InfoTextWithLabel from "./InfoTextWithUnit";

interface AssetEconomicsViewOnlyProp {
  wellEconomicsData: WellEconomicsStore;
}

const AssetEconomicsViewOnly: FC<AssetEconomicsViewOnlyProp> = ({
  wellEconomicsData,
}) => {
  const parsedDefaultInputs = useMemo(
    () => convertNegativeToParenthesis(wellEconomicsData.defaultValues.Inputs),
    [wellEconomicsData.defaultValues.Inputs]
  );

  return (
    <div className="well-info-container well-economics-analysis-view-only-container">
      {constant.WELL_ECONOMICS_INPUT_FIELDS.map((input, index) => (
        <div className="well-info-item" key={`view-only-${index}`}>
          <WellInfoText
            label={input.label}
            value={
              <InfoTextWithLabel
                value={parsedDefaultInputs[input.key] ?? "0"}
                unit={input.unit}
              />
            }
          />
        </div>
      ))}
    </div>
  );
};

export default AssetEconomicsViewOnly;
