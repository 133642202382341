import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Plot from "react-plotly.js";

import { Data } from "plotly.js";

import {
  AxisRange,
  IChartData,
  IChartTypeInfo,
  ITraceData,
  ProductionChartWellData,
  ProductionPlotChartProps,
} from "../../../types/charts/chartType/chartType";
import { Modules } from "../../../types/modularity/modules";

import { CHART_MODE_VIEW } from "../../../constants/charts/chartModeView";
import { CHART_TYPES } from "../../../constants/charts/charts";

import useChartStore from "../../../store/chart/chartStore";
import useMapSelectionStore from "../../../store/map/selection/mapSelectionStore";
import useModularityStore from "../../../store/modularity/modularityStore";

import useAnalysisChartRefetch from "../../../customHooks/charts/useAnalysisChartRefetch";
import { useChartData } from "../../../customHooks/charts/useChartData";
import useProductionData from "../../../customHooks/charts/useProductionData";
import useProductionDataChartTransformation from "../../../customHooks/charts/useProductionDataChartTransformation";
import useExportToExcel from "../../../customHooks/exports/useExportToExcel";
import { useProductionPlotExport } from "../../../customHooks/exports/useProductionPlotExport";

import chartToExcel from "../../../utils/charts/chartToExcel";
import {
  config,
  fontLayout,
  generalLayout,
  legendLayout,
  marginLayout,
  xAxisLayout,
  yAxisLayout,
} from "../../../utils/charts/layouts/ProductionPlotLayout";
import { truncateString } from "../../../utils/stringUtils";

import { clone } from "../../../utils";
import LoadingBackDrop from "../../common/LoadingBackDrop";
import { TGSLogo } from "../../common/icons";
import ChartTypeHeader from "./chartTypeHeader/ChartTypeHeader";

const ProductionPlotChart = ({
  chartType,
  chartId,
  onChartFullScreenClose,
  chartMode,
  isChartExport = false,
}: ProductionPlotChartProps) => {
  const modules = useModularityStore((state) => state.modules);
  const updateModuleDataByKey = useModularityStore(
    (state) => state.updateModuleDataByKey
  );

  const selectedWellIdsKeys = useMapSelectionStore(
    (state) => state.selectedWellIdsKeys
  );
  const chartData = useChartStore((state) => state.chartData);
  const getDataFlag = useChartStore((state) => state.chartDataRefetchFlag);
  const updateGetDataFlag = useChartStore(
    (state) => state.setChartDataRefetchFlag
  );
  const updateChartDataByKey = useChartStore(
    (state) => state.updateChartDataByKey
  );
  const { refetch, updateRefetch } = useAnalysisChartRefetch({
    chartMode,
    chartId,
    chartType,
  });
  const isFullScreenAnalysisPane = useModularityStore(
    (state) => state.isFullScreenAnalysisPane
  );
  const isFullScreenIndividualChart = useModularityStore(
    (state) => state.isFullScreenIndividualChart
  );
  const fullScreenChartId = useModularityStore(
    (state) => state.fullScreenChartId
  );

  const [plotlyLayout, setPlotlyLayout] = useState<any>();
  const [currentTickData, setCurrentTickData] = useState<number[]>([]);
  const [chartRendered, setChartRendered] = useState<boolean>(false);
  const [plotlyDataInfo, setPlotlyDataInfo] = useState<Data[] | []>([]);
  const [currentChartData, setCurrentChartData] = useState<
    IChartData | undefined
  >(undefined);
  const [shouldFormatXTicks, setShouldFormatXTicks] = useState<boolean>(false);

  const {
    getProductionData,
    isLoading,
    data: parsedData,
  } = useProductionData();
  const { formatChartTrace, formatChartCustomTicks, formatXTicks } =
    useProductionDataChartTransformation();
  const { onLegendClick } = useChartData();
  const { exportToExcel } = useExportToExcel();
  const { generateExportFileName } = useProductionPlotExport();
  const chartRef = useRef<any>();

  useEffect(() => {
    if (!chartId) return;
    const chart = chartData?.find((data) => data.chartId === chartId) as
      | IChartData
      | undefined;

    if (chart) {
      setCurrentChartData(chart);

      if (chart.chartData?.length) {
        let customTicksVal: number[] = [];
        let isXTicksFormatted = false;
        customTicksVal = formatChartCustomTicks(chart.chartData);
        isXTicksFormatted = formatXTicks(chart.chartData);
        setCurrentTickData(customTicksVal);
        setShouldFormatXTicks(!isXTicksFormatted);
      } else {
        setCurrentTickData([-Infinity, -Infinity, -Infinity]);
        setShouldFormatXTicks(false);
      }
    }
  }, [chartData]);

  useEffect(() => {
    if (refetch && currentChartData) {
      getProductionData(currentChartData);
    }
  }, [refetch]);

  useEffect(() => {
    if (!selectedWellIdsKeys.length) {
      setPlotlyDataInfo([]);
      const modifyChartData: IChartTypeInfo[] = clone(chartData);
      const updatedChartData = modifyChartData.find(
        (data: IChartTypeInfo) => data.chartId === chartId
      );

      if (
        updatedChartData &&
        updatedChartData.objectType == CHART_TYPES.PRODUCTION_PLOT
      ) {
        updatedChartData.chartData = [];
        updatedChartData.chartRawData = null;

        updateChartDataByKey(chartId, updatedChartData);
      }
    }
  }, [selectedWellIdsKeys]);

  const updateModuleRefetch = () => {
    const chartModule: Modules | undefined = (clone(modules) as Modules[]).find(
      (module) => module.module === chartId
    );

    if (chartModule) {
      chartModule.refetch = false;

      updateModuleDataByKey(chartId, chartModule);
    }

    updateRefetch(false);
  };

  useEffect(() => {
    if (!parsedData) {
      setPlotlyDataInfo([]);
      return;
    }

    if (!selectedWellIdsKeys.length) {
      updateModuleRefetch();
      return;
    }

    const { groupedByChartData, groupedByChartDataForecast } = parsedData;
    let transformedDataProductions: ITraceData[] = [];
    transformedDataProductions = formatChartTrace(
      chartId,
      groupedByChartData,
      currentChartData?.groupBy,
      false,
      []
    );

    let transformedDataProductionsForecasts: ITraceData[] = [];

    if (currentChartData?.chartShowForecast) {
      transformedDataProductionsForecasts = formatChartTrace(
        chartId,
        groupedByChartDataForecast,
        currentChartData?.groupBy,
        true,
        transformedDataProductions
      );
    }

    const copiedChartData: IChartTypeInfo[] = clone(chartData);
    const modifiedChartData: IChartTypeInfo[] = copiedChartData.map(
      (chart: IChartTypeInfo) => {
        if (
          chart.chartId === chartId &&
          chart.objectType === CHART_TYPES.PRODUCTION_PLOT
        ) {
          chart.chartData = [
            ...transformedDataProductions,
            ...(currentChartData?.chartShowForecast
              ? transformedDataProductionsForecasts
              : []),
          ];
          chart.chartRawData = {
            historical: groupedByChartData,
            forecast: groupedByChartDataForecast,
          };
        }
        return chart;
      }
    );
    const updatedChartData: IChartTypeInfo | undefined = modifiedChartData.find(
      (data) => data.chartId === chartId
    );

    if (updatedChartData) {
      updateChartDataByKey(chartId, updatedChartData);
    }

    updateModuleRefetch();

    if (getDataFlag.refetch) {
      updateGetDataFlag({ chartId: "", refetch: false });
    }
  }, [parsedData]);

  useEffect(() => {
    if (
      ((fullScreenChartId === chartId &&
        isFullScreenIndividualChart &&
        chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART) ||
        (isFullScreenAnalysisPane &&
          chartMode === CHART_MODE_VIEW.FULLSCREEN_ANALYSIS)) &&
      currentChartData?.chartData
    ) {
      const copiedChartData: ITraceData[] = clone(currentChartData.chartData);

      const mappedData: Data[] = copiedChartData?.map((trace: ITraceData) => {
        const obj: Data = {
          name: `${trace.id} ${trace.unit !== "" ? `, ${trace.unit}` : ""}`,
          type: trace.type,
          mode: trace.x.length > 1 ? trace.mode : "markers",
          ...(trace.line && {
            line: trace.line,
          }),
          hoverlabel: trace.hoverlabel,
          hoverinfo: trace.hoverinfo,
          ...(trace.xhoverformat && {
            xhoverformat: trace.xhoverformat,
          }),
          hovertemplate: trace.hovertemplate,
          text: trace.text,
          marker: trace.marker,
          x: trace.x,
          y: trace.y,
          customdata: trace.customdata,
          meta: trace.meta,
          visible: trace.visible,
        };

        return obj;
      });

      setPlotlyDataInfo(mappedData);
    } else if (
      currentChartData?.chartData &&
      chartMode === CHART_MODE_VIEW.DEFAULT
    ) {
      const copiedChartData: ITraceData[] = clone(currentChartData.chartData);

      const mappedData: Data[] = copiedChartData?.map((trace: ITraceData) => {
        const obj: Data = {
          name: !isChartExport
            ? `${truncateString(trace.id, 12)} ${
                trace.unit !== "" ? `, ${trace.unit}` : ""
              }`
            : `${trace.id}, ${trace.unit}`,
          type: trace.type,
          mode: trace.x.length > 1 ? trace.mode : "markers",
          ...(trace.line && {
            line: trace.line,
          }),
          hoverlabel: trace.hoverlabel,
          hoverinfo: trace.hoverinfo,
          ...(trace.xhoverformat && {
            xhoverformat: trace.xhoverformat,
          }),
          hovertemplate: trace.hovertemplate,
          text: trace.text,
          marker: trace.marker,
          x: trace.x,
          y: trace.y,
          customdata: trace.customdata,
          meta: trace.meta,
          visible: trace.visible,
        };

        return obj;
      });

      setPlotlyDataInfo(mappedData);
    }
  }, [
    currentChartData,
    chartMode,
    isFullScreenAnalysisPane,
    isFullScreenIndividualChart,
    fullScreenChartId,
  ]);

  useEffect(() => {
    //Triggers when reLayout throws a callback [NOTE: Even on new generation, it triggers]
    const layout = plotlyLayout;
    let layoutCount = 0;
    //Check if layout contains the right axis values so we count the length, length should be more than 1. [Expected: 4, 2 items per axis]
    if (layout) {
      layoutCount = Object.keys(layout).length;
    }
    if (layoutCount > 1) {
      const xAxisLower = layout?.["xaxis.range[0]"];
      const xAxisUpper = layout?.["xaxis.range[1]"];
      const yAxisLower = layout?.["yaxis.range[0]"];
      const yAxisUpper = layout?.["yaxis.range[1]"];
      const xLegend = layout?.["legend.x"];
      const yLegend = layout?.["legend.y"];

      const copiedChartData: IChartTypeInfo[] = clone(chartData);

      const newChartData = copiedChartData.map((chart) => {
        if (
          chart.chartId === chartId &&
          chart.objectType === CHART_TYPES.PRODUCTION_PLOT
        ) {
          let mappedXRange: AxisRange = [undefined, undefined];
          if (xAxisLower && xAxisUpper) {
            mappedXRange = [xAxisLower, xAxisUpper];
          } else {
            if (chart.chartRange?.xRange) {
              mappedXRange = chart.chartRange?.xRange;
            }
          }

          let mappedYRange: AxisRange = [undefined, undefined];
          if (yAxisLower && yAxisUpper) {
            mappedYRange = [yAxisLower, yAxisUpper];
          } else {
            if (chart.chartRange?.xRange) {
              mappedYRange = chart.chartRange?.yRange;
            }
          }

          let mappedXLegend = undefined;
          if (xLegend) {
            mappedXLegend = xLegend;
          } else {
            if (chart.chartRange?.xLegend) {
              mappedXLegend = chart.chartRange?.xLegend;
            }
          }

          let mappedYLegend = undefined;
          if (yLegend) {
            mappedYLegend = yLegend;
          } else {
            if (chart.chartRange?.yLegend) {
              mappedYLegend = chart.chartRange?.yLegend;
            }
          }

          chart.chartRange = {
            xRange: mappedXRange,
            yRange: mappedYRange,
            autorange: false,
            ...(mappedXLegend && {
              xLegend: mappedXLegend,
            }),
            ...(mappedYLegend && {
              yLegend: mappedYLegend,
            }),
          };
        }
        return chart;
      });

      const updatedChartData: IChartTypeInfo | undefined = newChartData.find(
        (data) => data.chartId === chartId
      );

      if (updatedChartData) {
        updateChartDataByKey(chartId, updatedChartData);
      }
    }
  }, [plotlyLayout]);

  const chartExportTitle: JSX.Element = useMemo(() => {
    if (!isChartExport) return <></>;

    return (
      <div className="chart-name">
        {currentChartData?.title === ""
          ? chartType + "_" + chartId + " | "
          : currentChartData?.title + " | "}
        <div className="chart-type">{chartType}</div>
      </div>
    );
  }, [isChartExport, currentChartData]);

  const chartExportLogo: JSX.Element = useMemo(() => {
    if (!isChartExport) return <></>;
    return (
      <div className="tgs-logo-watermark">
        <TGSLogo />
      </div>
    );
  }, [isChartExport]);

  const onChartRelayout = (figure: any) => {
    if (isChartExport) {
      setChartRendered(true);
    }
    if (figure?.autosize) {
      //on initialization, first value of the callBack is just {autosize: true} so we should negate the call
      return;
    }
    setPlotlyLayout(figure);
  };

  const handleResetChart = () => {
    const copiedChartData: IChartTypeInfo[] = clone(chartData);
    const modifiedChartData: IChartTypeInfo[] = copiedChartData.map(
      (chart: IChartTypeInfo) => {
        if (
          chart.chartId === chartId &&
          chart.objectType === CHART_TYPES.PRODUCTION_PLOT
        ) {
          chart.chartRange = undefined;
        }
        return chart;
      }
    );
    const updatedChartData: IChartTypeInfo | undefined = modifiedChartData.find(
      (data) => data.chartId === chartId
    );

    if (updatedChartData) {
      updateChartDataByKey(chartId, updatedChartData);
    }
  };

  const onExportData = useCallback(() => {
    const fileName = generateExportFileName(
      chartType,
      chartId,
      currentChartData?.groupBy,
      currentChartData?.title
    );

    const dataForExport = chartToExcel(
      (currentChartData?.chartRawData as ProductionChartWellData)?.historical ??
        [],
      (currentChartData?.chartRawData as ProductionChartWellData)?.forecast ??
        [],
      chartType,
      currentChartData?.groupBy,
      currentChartData?.chartShowDailyValues
    );

    exportToExcel(dataForExport, fileName);
  }, [
    currentChartData?.chartRawData,
    currentChartData?.chartShowDailyValues,
    currentChartData?.groupBy,
    currentChartData?.title,
  ]);

  return (
    <>
      {!refetch && !isLoading ? (
        <>
          <ChartTypeHeader
            chartId={chartId}
            isChartExport={isChartExport}
            chartMode={chartMode}
            onChartFullScreenClose={onChartFullScreenClose}
            chartRendered={chartRendered}
            chartRef={chartRef}
            onChartExportData={onExportData}
          />
          <div className="plot-chart-container" id={chartId} ref={chartRef}>
            {chartExportTitle}
            <Plot
              onDoubleClick={handleResetChart}
              divId={chartType}
              className="plot-chart"
              useResizeHandler={true}
              data={plotlyDataInfo}
              config={config}
              layout={{
                ...generalLayout(
                  chartMode === CHART_MODE_VIEW.FULLSCREEN_ANALYSIS ||
                    chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART
                ),
                margin: {
                  ...marginLayout,
                },
                font: {
                  ...fontLayout,
                },
                legend: {
                  ...legendLayout(currentChartData?.chartRange),
                },
                xaxis: {
                  ...xAxisLayout(
                    currentChartData?.chartRange,
                    shouldFormatXTicks
                  ),
                },
                yaxis: {
                  ...yAxisLayout(
                    currentChartData?.chartYAxisScaling,
                    currentChartData?.chartRange,
                    currentTickData,
                    currentChartData?.chartShowDailyValues ?? false
                  ),
                },
              }}
              onRelayout={(figure: any) => {
                onChartRelayout(figure);
              }}
              onLegendClick={(e) => {
                onLegendClick(chartId, e);
                return true;
              }}
            />
            {isChartExport && chartExportLogo}
          </div>
        </>
      ) : (
        <LoadingBackDrop
          className={`plot-chart-area ${
            fullScreenChartId === chartId &&
            isFullScreenIndividualChart &&
            chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART
              ? "full-screen-chart"
              : ""
          } loader`}
          isOpen
        />
      )}
    </>
  );
};

export default ProductionPlotChart;
