import { useEffect, useState } from "react";

import { Data } from "plotly.js";

import {
  FreeCashFlowData,
  FreeCashFlowMonthPositive,
} from "../../types/charts/chartType/cashFlowChartData";
import { ITraceData } from "../../types/charts/chartType/chartType";

import {
  ASSET_LEVEL_SCATTER_DATA,
  OPERATING_COST_BAR_DATA,
  REVENUE_BAR_DATA,
} from "../../constants/charts/cashFlowChart";
import { MONTHS_NUMERIC_VALUE } from "../../constants/charts/charts";

import useAlertPopupStore from "../../store/map/alert/alertStore";

import { clone } from "../../utils";
import useWellEconomicsOutputData from "../panels/wellPanel/useWellEconomicsData";

interface UseCashFlowChartDataProps {
  updateModuleRefetch: () => void;
}

const useCashFlowChartData = ({
  updateModuleRefetch,
}: UseCashFlowChartDataProps) => {
  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );
  const [chartData, setChartData] = useState<Data[]>([]);
  const {
    data: wellEconomicsResponseData,
    loading: isLoading,
    getWellEconomicsOutputData,
  } = useWellEconomicsOutputData();

  const formatChartTrace = (cashFlowData: FreeCashFlowData) => {
    const revenueBarData: ITraceData = clone(REVENUE_BAR_DATA);
    const costBarData: ITraceData = clone(OPERATING_COST_BAR_DATA);
    const fcfScatterData: ITraceData = clone(ASSET_LEVEL_SCATTER_DATA);

    cashFlowData.forEach((d, idx) => {
      if (idx === 0) return;

      const data = d as FreeCashFlowMonthPositive;
      const {
        Date: date,
        TotalRevenue,
        TotalNonOperatingCost,
        TotalOperatingCost,
        FreeCashFlow,
        Year,
      } = data;
      // adding a default empty string in case there are bad data
      const month = (date ?? "").slice(0, 3);

      const formattedDate = `${Year}-${MONTHS_NUMERIC_VALUE[month]}`;
      revenueBarData.x.push(formattedDate);
      revenueBarData.y.push(Math.round(TotalRevenue));

      costBarData.x.push(formattedDate);
      costBarData.y.push(
        Math.round(TotalNonOperatingCost + TotalOperatingCost)
      );

      fcfScatterData.x.push(formattedDate);
      fcfScatterData.y.push(Math.round(FreeCashFlow));
    });
    const formattedChartData = [revenueBarData, costBarData, fcfScatterData];
    setChartData(formattedChartData);
  };

  useEffect(() => {
    if (isLoading || !wellEconomicsResponseData) return;

    if (wellEconomicsResponseData.FreeCashFlow) {
      formatChartTrace(wellEconomicsResponseData.FreeCashFlow);
    } else {
      updateAlertState({
        severity: "error",
        message: "No economics data available for selected wells.",
      });
      updateModuleRefetch();
    }
  }, [isLoading, wellEconomicsResponseData]);

  return {
    isLoading,
    cashFlowData: wellEconomicsResponseData?.FreeCashFlow,
    cashFlowChartTraceData: chartData,
    getCashFlowChartData: getWellEconomicsOutputData,
  };
};

export default useCashFlowChartData;
