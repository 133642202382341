import {
  WellEconomicsInputField,
  WellEconomicsInputFields,
  WellEconomicsInputs,
} from "../../../types/panels/wellPanel/wellEconomicsType";

import { noData } from "../../../constants/constants";

import {
  formatNumberInK,
  getNumberWithComma,
} from "../../formatters/numberFormatter";
import { clone, roundNumber } from "../../helper";

//format negatives with parenthesis
export const formatWellEconomicsOutputValue = (
  value: number | string,
  scale: number,
  formatK = false
) => {
  if (typeof value !== "number") return noData;

  let isNegative = false;
  const num = value;

  if (num < 0) isNegative = true;

  const roundedNum = scale > 0 ? roundNumber(Math.abs(num), scale) : num;

  const formattedNum =
    formatK && Math.abs(num) > 999
      ? formatNumberInK(roundedNum, 1000)
      : getNumberWithComma(roundedNum);

  if (isNegative) {
    return `(${formattedNum})`;
  }

  return formattedNum;
};

//parse negative (dash) to parenthesis
//add commas
export const convertNegativeToParenthesis = (inputs: WellEconomicsInputs) => {
  const parsedData = clone(inputs) as WellEconomicsInputField;
  Object.keys(parsedData).forEach((key) => {
    const keyField = key as WellEconomicsInputFields;

    if (parsedData[keyField] !== undefined && parsedData[keyField] !== null) {
      const value = parsedData[keyField];

      if (typeof value === "number" && value < 0) {
        const absolutevalue = Math.abs(value);
        parsedData[keyField] = `(${getNumberWithComma(absolutevalue)})`;
      } else {
        const parsedVal = typeof value === "string" ? parseFloat(value) : value;

        parsedData[keyField] = getNumberWithComma(parsedVal ?? 0);
      }
    }
  });

  return parsedData;
};
