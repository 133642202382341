import { FC } from "react";

interface InfoTextWithLabelProps {
  value?: string | boolean | number | Date;
  unit: string;
}

const InfoTextWithLabel: FC<InfoTextWithLabelProps> = ({ value, unit }) => {
  return (
    <div className="text-with-unit-container">
      <div>{value?.toString()}</div>
      <div className="text-unit-label">{unit}</div>
    </div>
  );
};

export default InfoTextWithLabel;
