import { memo, useMemo } from "react";

import { IChartTypeInfo } from "../../../types/charts/chartType/chartType";

import { CHART_MODE_VIEW } from "../../../constants/charts/chartModeView";
import { CHART_TYPES } from "../../../constants/charts/charts";

import useChartStore from "../../../store/chart/chartStore";
import useModularityStore from "../../../store/modularity/modularityStore";

import FullScreenView from "../../common/FullScreenView";
import AssetEconomics from "../chartType/AssetEconomics/AssetEconomics";
import CashFlowChart from "../chartType/CashFlow/CashFlowChart";
import ProductionPlotChart from "../chartType/ProductionPlotChart";
import ScatterPlotChart from "../chartType/ScatterPlotChart";
import TypeCurveChart from "../chartType/TypeCurveChart";

export const ChartTypeFullScreen = () => {
  const chartMode = CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART;
  const isFullScreenIndividualChart = useModularityStore(
    (state) => state.isFullScreenIndividualChart
  );
  const fullScreenChartId = useModularityStore(
    (state) => state.fullScreenChartId
  );
  const chartData = useChartStore((state) => state.chartData);

  const toggleFullScreenIndividualChart = useModularityStore(
    (state) => state.toggleFullScreenIndividualChart
  );
  const updateFullScreenChartId = useModularityStore(
    (state) => state.updateFullScreenChartId
  );
  const handleClose = () => {
    toggleFullScreenIndividualChart(false);
    updateFullScreenChartId(undefined);
  };

  const activeChart: IChartTypeInfo | undefined = useMemo(() => {
    return chartData.find((chart) => chart.chartId === fullScreenChartId);
  }, [fullScreenChartId]);

  return (
    <>
      <FullScreenView
        open={isFullScreenIndividualChart && activeChart !== undefined}
        showCloseIcon={false}
      >
        <>
          {activeChart?.chartType === CHART_TYPES.PRODUCTION_PLOT && (
            <ProductionPlotChart
              chartType={activeChart.chartType}
              chartId={activeChart.chartId}
              onChartFullScreenClose={handleClose}
              chartMode={chartMode}
            />
          )}
          {activeChart?.chartType === CHART_TYPES.TYPE_CURVE && (
            <TypeCurveChart
              chartType={activeChart.chartType}
              chartId={activeChart.chartId}
              onChartFullScreenClose={handleClose}
              chartMode={chartMode}
            />
          )}
          {activeChart?.chartType === CHART_TYPES.SCATTER_PLOT && (
            <ScatterPlotChart
              chartType={activeChart.chartType}
              chartId={activeChart.chartId}
              onChartFullScreenClose={handleClose}
              chartMode={chartMode}
            />
          )}
          {activeChart?.chartType === CHART_TYPES.ASSET_ECONOMICS && (
            <AssetEconomics
              chartType={activeChart.chartType}
              chartId={activeChart.chartId}
              onChartFullScreenClose={handleClose}
              chartMode={chartMode}
            />
          )}
          {activeChart?.chartType === CHART_TYPES.CASH_FLOW && (
            <CashFlowChart
              chartType={activeChart.chartType}
              chartId={activeChart.chartId}
              onChartFullScreenClose={handleClose}
              chartMode={chartMode}
            />
          )}
        </>
      </FullScreenView>
    </>
  );
};
export default memo(ChartTypeFullScreen);
