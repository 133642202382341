import { Attribute } from "../../../types/common/attributes";
import { PressureDataFieldsDisplay } from "../../../types/panels/wellPanel/pressureDataType";

import * as attributes from "../../attributes";

//Pressure Data Attributes
export const TEST_TYPE: Attribute = {
  key: "DocType",
  label: "Test Type",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const DST_NO: Attribute = {
  key: "DstNumber",
  label: "DST_No",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const REPORTED_FORMATION: Attribute = {
  key: "ReportedFormation",
  label: "Reported Formation",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const TGS_LANDING_ZONE: Attribute = {
  ...attributes.INTERPRETED_PRODUCING_FORMATION,
  key: "TgsLandingZone",
};

export const DST_TEMP: Attribute = {
  key: "DstTemp",
  label: "Temperature",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const INT_F: Attribute = {
  key: "IntF",
  label: "Top",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const INT_T: Attribute = {
  key: "IntT",
  label: "Bottom",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const FIRST_FLOW_DURATION: Attribute = {
  key: "FirstFlowDuration",
  label: "First Flow Duration",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const FIRST_FLOW_END: Attribute = {
  key: "FirstFlowEnd",
  label: "First Flow End",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const FIRST_SHUTIN_DURATION: Attribute = {
  key: "FirstShutinDuration",
  label: "First Shutin Duration",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const FIRST_SHUTIN_END: Attribute = {
  key: "FirstShutinEnd",
  label: "First Shutin End",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const SECOND_FLOW_DURATION: Attribute = {
  key: "SecondFlowDuration",
  label: "Second Flow Duration",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const SECOND_FLOW_END: Attribute = {
  key: "SecondFlowEnd",
  label: "Second Flow End",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const SECOND_SHUTIN_DURATION: Attribute = {
  key: "SecondShutinDuration",
  label: "Second Shutin Duration",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const SECOND_SHUTIN_END: Attribute = {
  key: "SecondShutinEnd",
  label: "Second Shutin End",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const FIRST_RECOVERY_AMT: Attribute = {
  key: "FirstRecoveryAmt",
  label: "First Recovery Amount",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const FIRST_RECOVERY_AMT_UNITS: Attribute = {
  key: "FirstRecoveryAmtUnits",
  label: "First Recovery Amount Units",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FIRST_RECOVERY_DESC: Attribute = {
  key: "FirstRecoveryDesc",
  label: "First Recovery Description",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const SECOND_RECOVERY_AMT: Attribute = {
  key: "SecondRecoveryAmt",
  label: "Second Recovery Amount",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const SECOND_RECOVERY_AMT_UNITS: Attribute = {
  key: "SecondRecoveryAmtUnits",
  label: "Second Recovery Amount Units",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const SECOND_RECOVERY_DESC: Attribute = {
  key: "SecondRecoveryDesc",
  label: "Second Recovery Description",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const THIRD_RECOVERY_AMT: Attribute = {
  key: "ThirdRecoveryAmt",
  label: "Third Recovery Amount",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const THIRD_RECOVERY_AMT_UNITS: Attribute = {
  key: "ThirdRecoveryAmtUnits",
  label: "Third Recovery Amount Units",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const THIRD_RECOVERY_DESC: Attribute = {
  key: "ThirdRecoveryDesc",
  label: "Third Recovery Description",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FOURTH_RECOVERY_AMT: Attribute = {
  key: "FourthRecoveryAmt",
  label: "Fourth Recovery Amount",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const FOURTH_RECOVERY_AMT_UNITS: Attribute = {
  key: "FourthRecoveryAmtUnits",
  label: "Fourth Recovery Amount Units",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FOURTH_RECOVERY_DESC: Attribute = {
  key: "FourthRecoveryDesc",
  label: "Fourth Recovery Description",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FIFTH_RECOVERY_AMT: Attribute = {
  key: "FifthRecoveryAmt",
  label: "Fifth Recovery Amount",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const FIFTH_RECOVERY_AMT_UNITS: Attribute = {
  key: "FifthRecoveryAmtUnits",
  label: "Fifth Recovery Amount Units",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FIFTH_RECOVERY_DESC: Attribute = {
  key: "FifthRecoveryDesc",
  label: "Fifth Recovery Description",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const SIXTH_RECOVERY_AMT: Attribute = {
  key: "SixthRecoveryAmt",
  label: "Sixth Recovery Amount",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const SIXTH_RECOVERY_AMT_UNITS: Attribute = {
  key: "SixthRecoveryAmtUnits",
  label: "Sixth Recovery Amount Units",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const SIXTH_RECOVERY_DESC: Attribute = {
  key: "SixthRecoveryDesc",
  label: "Sixth Recovery Description",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const PRESSURE_DATA_FIELDS = [
  "Uwi",
  TEST_TYPE.key,
  DST_NO.key,
  REPORTED_FORMATION.key,
  TGS_LANDING_ZONE.key,
  DST_TEMP.key,
  INT_F.key,
  INT_T.key,
  FIRST_FLOW_DURATION.key,
  FIRST_FLOW_END.key,
  FIRST_SHUTIN_DURATION.key,
  FIRST_SHUTIN_END.key,
  SECOND_FLOW_DURATION.key,
  SECOND_FLOW_END.key,
  SECOND_SHUTIN_DURATION.key,
  SECOND_SHUTIN_END.key,
  FIRST_RECOVERY_AMT.key,
  FIRST_RECOVERY_AMT_UNITS.key,
  FIRST_RECOVERY_DESC.key,
  SECOND_RECOVERY_AMT.key,
  SECOND_RECOVERY_AMT_UNITS.key,
  SECOND_RECOVERY_DESC.key,
  THIRD_RECOVERY_AMT.key,
  THIRD_RECOVERY_AMT_UNITS.key,
  THIRD_RECOVERY_DESC.key,
  FOURTH_RECOVERY_AMT.key,
  FOURTH_RECOVERY_AMT_UNITS.key,
  FOURTH_RECOVERY_DESC.key,
  FIFTH_RECOVERY_AMT.key,
  FIFTH_RECOVERY_AMT_UNITS.key,
  FIFTH_RECOVERY_DESC.key,
  SIXTH_RECOVERY_AMT.key,
  SIXTH_RECOVERY_AMT_UNITS.key,
  SIXTH_RECOVERY_DESC.key,
  "Incremental",
  "HornerPlot",
];

export const PRESSURE_DATA_SEARCHTYPES = ["Dst", "DstPlus"] as const;

export const PRESSURE_DATA_SORT_FIELDS = [TEST_TYPE.key];

export const PRESSURE_DATA_TEST_TYPES = {
  DST: "Dst",
  DST_PLUS: "DstPlus",
} as const;

export const PRESSURE_DATA_FIELDS_DISPLAY: PressureDataFieldsDisplay[] = [
  { label: TGS_LANDING_ZONE.label, propertyKey: "TgsLandingZone" },
  { label: DST_TEMP.label, propertyKey: "Temperature" },
  { label: INT_F.label, propertyKey: "Top" },
  { label: INT_T.label, propertyKey: "Bottom" },
  { label: "1st Flow", propertyKey: "FirstFlow" },
  { label: "1st Shut-In", propertyKey: "FirstFlowShutIn" },
  { label: "2nd Flow", propertyKey: "SecondFlow" },
  { label: "2nd Shut-In", propertyKey: "SecondFlowShutIn" },
  { label: "Recovery Remarks", propertyKey: "RecoveryRemarks" },
];

export const DST_TEST_TYPE_LABEL = "DST";
