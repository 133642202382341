import { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";

import { PanelDrawerProps } from "../../../types/panels/common/panelDrawer";
import { GetWellCardDataProps } from "../../../types/panels/wellPanel/hooks";
import {
  MapLayer,
  WellPanelInfo,
  WellSystemData,
} from "../../../types/panels/wellPanel/wellPanel";

import {
  DYNAMIC_BOTTOM_WELL_SPOTS,
  DYNAMIC_WELL_SPOTS,
  WELL_SPOTS,
} from "../../../constants/constants";
import { WELL_PANEL_FLAGS } from "../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../store/panels/panelsStore";

import useGetWellPanelData from "../../../customHooks/panels/wellPanel/useGetWellPanelData";

import { clone } from "../../../utils/helper";

import Loading from "../../common/Loading";
import PanelDrawer from "../common/PanelDrawer";
import DstPanel from "../dstPanel/DstPanel";
import WellPanelContent from "./WellPanelContent";
import WellPanelFooter from "./WellPanelFooter";
import WellPanelHeader from "./WellPanelHeader";

interface WellPanelProps extends PanelDrawerProps {
  wellId: number;
  layer: MapLayer;
  parentWellId: number;
  bottomWellBoreId: number;
  uwi: string;
  groupedWellID: number;
}
const WellPanel = ({
  open,
  handleCloseMenuClick,
  wellId,
  layer,
  bottomWellBoreId,
  parentWellId,
  uwi,
  groupedWellID,
}: WellPanelProps) => {
  const [wellHasDstData, setWellHasDstData] = useState<boolean>(false);
  const wellInfoList = usePanelsStore((state) => state.wellList);

  const updateWellCardDataByKey = usePanelsStore(
    (state) => state.updateWellCardDataByKey
  );
  const updateWellPanelPaginationByKey = usePanelsStore(
    (state) => state.updateWellPanelPaginationByKey
  );
  const updateActiveWellPanelPressureData = usePanelsStore(
    (state) => state.updateActiveWellPanelPressureData
  );
  const {
    getWellCard,
    isWellCardDataLoading,
    wellCardData,
    isSurfaceAndBottomEqual,
  } = useGetWellPanelData();

  const [currentWellPanelInfo, setCurrentWellPanelInfo] = useState<
    WellPanelInfo | undefined
  >(undefined);
  const [currentViewedWellSystem, setCurrentViewedWellSystem] = useState<
    WellSystemData | undefined
  >(undefined);

  const updateIsSurfaceBottomEqual = usePanelsStore(
    (state) => state.updateIsSurfaceBottomEqual
  );

  // TODO: This can be removed when panel info is passed as props
  useEffect(() => {
    const copiedWellInfoList: WellPanelInfo[] = clone(wellInfoList);
    const searchedWellPanelInfo = copiedWellInfoList.find(
      (info) =>
        info.parentWellId === parentWellId &&
        info.bottomWellBoreId === bottomWellBoreId &&
        info.wellType === layer
    );
    if (searchedWellPanelInfo) {
      setCurrentWellPanelInfo(searchedWellPanelInfo);
    }
  }, [wellInfoList]);

  useEffect(() => {
    if (
      !currentWellPanelInfo ||
      (currentWellPanelInfo.wellSystemData &&
        currentWellPanelInfo.wellSystemData.every(
          (wellData) => wellData.wellCardData.isDataLoaded
        ))
    ) {
      const findWellSystem = currentWellPanelInfo?.wellSystemData?.find(
        (data) => data.pageNumber === currentWellPanelInfo.activePage
      );
      setCurrentViewedWellSystem(findWellSystem);
      setWellHasDstData(
        Boolean(findWellSystem?.wellCardData[WELL_PANEL_FLAGS.HAS_DST.key])
      );
      return;
    }

    const isSurfaceLayer = [DYNAMIC_WELL_SPOTS, WELL_SPOTS].includes(
      currentWellPanelInfo.wellType ?? ""
    );

    const getWellCardDataProps: GetWellCardDataProps = isSurfaceLayer
      ? {
          parentWellId: currentWellPanelInfo.parentWellId,
          layer: currentWellPanelInfo.wellType ?? DYNAMIC_WELL_SPOTS,
        }
      : {
          parentWellId: currentWellPanelInfo.parentWellId,
          bottomWellBoreId: currentWellPanelInfo.bottomWellBoreId,
          layer: currentWellPanelInfo.wellType ?? DYNAMIC_BOTTOM_WELL_SPOTS,
        };

    getWellCard(getWellCardDataProps);
    // updateWellInformation(WELL_INFORMATION_BY_KEY);
  }, [currentWellPanelInfo]);

  useEffect(() => {
    if (!currentWellPanelInfo?.wellType || !wellCardData || !groupedWellID) {
      return;
    }
    updateWellCardDataByKey(
      wellCardData,
      currentWellPanelInfo.wellType,
      groupedWellID,
      wellId
    );
  }, [wellCardData]);

  useEffect(() => {
    if (!currentWellPanelInfo || !wellCardData || !groupedWellID) {
      return;
    }
    updateIsSurfaceBottomEqual(isSurfaceAndBottomEqual, layer, groupedWellID);
  }, [isSurfaceAndBottomEqual]);

  const setWellPanelPage = (page: number) => {
    if (!groupedWellID) {
      return;
    }

    updateWellPanelPaginationByKey(layer, groupedWellID, page);
  };

  const handleCloseDstPanel = useCallback(() => {
    if (currentViewedWellSystem)
      updateActiveWellPanelPressureData(
        currentViewedWellSystem?.wellId,
        layer,
        groupedWellID
      );
  }, [currentViewedWellSystem]);

  const activeDstContent = useMemo(() => {
    if (wellHasDstData) {
      return currentViewedWellSystem?.pressureData?.find(
        (data) => data.IsSelected
      );
    } else {
      return undefined;
    }
  }, [wellHasDstData, currentViewedWellSystem?.pressureData]);

  return (
    <PanelDrawer
      className={classNames("well-panel", {
        "active-dst": Boolean(activeDstContent),
      })}
      open={open && (currentWellPanelInfo?.isActive ?? false)}
      handleCloseMenuClick={handleCloseMenuClick}
    >
      {!isWellCardDataLoading &&
      currentWellPanelInfo &&
      currentViewedWellSystem ? (
        <div className="well-panel-container">
          <div className="well-panel-content-container">
            <div className="well-panel-body">
              <WellPanelHeader
                wellPanelInfo={currentWellPanelInfo}
                data={currentViewedWellSystem}
                parentWellId={parentWellId}
                bottomWellBoreId={bottomWellBoreId}
                layer={layer}
                groupedWellID={groupedWellID ?? 0}
              />
              <WellPanelContent
                data={currentViewedWellSystem}
                activePage={currentWellPanelInfo.activePage}
                layer={layer}
                groupedWellID={groupedWellID ?? 0}
                uwi={uwi}
                surfaceUWI={currentWellPanelInfo.surfaceUWI}
                country={currentWellPanelInfo.country}
              />
            </div>

            <WellPanelFooter
              totalPages={
                currentWellPanelInfo && currentWellPanelInfo.wellSystemData
                  ? currentWellPanelInfo.wellSystemData.length
                  : 0
              }
              currentPage={currentWellPanelInfo?.activePage ?? 0}
              currentViewedWellPanel={currentViewedWellSystem}
              onPageChange={(page) => setWellPanelPage(page)}
            />
          </div>

          {wellHasDstData && activeDstContent && (
            <DstPanel
              panelContent={activeDstContent}
              handleCloseDstPanel={handleCloseDstPanel}
            />
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center py-5">
          <Loading />
        </div>
      )}
    </PanelDrawer>
  );
};

export default WellPanel;
