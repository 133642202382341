import { FC } from "react";

import { Button } from "@mui/material";

import classNames from "classnames";

import { PressureDataWellPanel } from "../../../../../types/panels/wellPanel/pressureDataType";
import { MapLayer } from "../../../../../types/panels/wellPanel/wellPanel";

import usePanelsStore from "../../../../../store/panels/panelsStore";

import DoubleArrowIcon from "../../../../common/icons/DoubleArrowIcon";

interface PressureDataSectionProps {
  data: PressureDataWellPanel[];
  wellId: number;
  layer: MapLayer;
  groupedWellID: number;
}

const PressureDataSection: FC<PressureDataSectionProps> = ({
  data,
  wellId,
  layer,
  groupedWellID,
}) => {
  const updateActiveWellPanelPressureData = usePanelsStore(
    (state) => state.updateActiveWellPanelPressureData
  );
  return (
    <>
      {data.map((pressureData) => (
        <Button
          variant="contained"
          className={classNames("dst-data-summary", {
            expanded: pressureData.IsSelected,
          })}
          key={pressureData.TestName}
          onClick={() => {
            updateActiveWellPanelPressureData(wellId, layer, groupedWellID, {
              TestType: pressureData.TestType,
              DSTNo: pressureData.DSTNo,
            });
          }}
        >
          <div className="dst-summary-info">
            <div className="title">{pressureData.TestName}</div>
            <div className="formation">
              {pressureData.Formation.toUpperCase()}
            </div>
            <DoubleArrowIcon />
          </div>
        </Button>
      ))}
    </>
  );
};

export default PressureDataSection;
