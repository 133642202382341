import { Data, Datum } from "plotly.js";

import {
  DatalakeHornerPlotData,
  DatalakeIncrementalData,
  DatalakePressureDataResponseBody,
  TestType,
} from "../../../types/panels/wellPanel/dataLakeType";
import {
  PressureDataWellPanel,
  RecoveryRemarksData,
} from "../../../types/panels/wellPanel/pressureDataType";

import {
  DST_TEST_TYPE_LABEL,
  PRESSURE_DATA_TEST_TYPES,
} from "../../../constants/panels/wellPanel/pressureData";

import { formatDefaultTopColorsToRGB } from "../../../utils/charts/formatter";
import {
  formatNumberInOrdinal,
  formatNumberWithDecimals,
  parseNumberData,
} from "../../../utils/formatters/numberFormatter";
import { sortPanelData } from "../../../utils/panel/wellPanel/sorting";

import { clone } from "../../../utils";
import { useFieldFormatter } from "../../common/useFieldFormatter";

const usePressureData = () => {
  const { stringValidation } = useFieldFormatter();
  const defaultTopColor = formatDefaultTopColorsToRGB();

  const getTestType = (testType: TestType) => {
    return testType === PRESSURE_DATA_TEST_TYPES.DST ||
      testType === PRESSURE_DATA_TEST_TYPES.DST_PLUS
      ? DST_TEST_TYPE_LABEL
      : "";
  };

  const createTestName = (testType: TestType, dstNumber: string) => {
    const dstTestTypeLabel = getTestType(testType);

    return `${dstTestTypeLabel ?? ""} - ${dstNumber ?? ""}`;
  };

  const formatTemp = (temp: number | null) => {
    return stringValidation(
      `${
        temp?.toString()
          ? `${formatNumberWithDecimals(temp, 0, true)} \u00B0F`
          : ""
      }`
    );
  };

  const formatDepthData = (depth: number | null) => {
    return stringValidation(
      `${depth?.toString() ? `${formatNumberWithDecimals(depth, 0)} ft` : ""}`
    );
  };

  const formatPressureData = (duration: number | null, end: number | null) => {
    return stringValidation(
      `${
        duration?.toString()
          ? `${formatNumberWithDecimals(duration, 0, true)}' `
          : ""
      }${
        end?.toString() ? `${formatNumberWithDecimals(end, 0, true)} psi` : ""
      }`
    );
  };

  const formatRecoveryRemarks = (recoveryData: RecoveryRemarksData) => {
    let recoveryRemarksText = "";

    const buildRemarks = (
      amt: number | null,
      units: string | null,
      desc: string | null
    ) => {
      return `${
        amt?.toString() ? `${formatNumberWithDecimals(amt, 0, true)} ` : ""
      }${units ? `${units} ` : ""}${desc ? `${desc} ` : ""}`;
    };

    const firstRecovery = buildRemarks(
      recoveryData.FirstRecoveryAmt,
      recoveryData.FirstRecoveryAmtUnits,
      recoveryData.FirstRecoveryDesc
    );
    const secondRecovery = buildRemarks(
      recoveryData.SecondRecoveryAmt,
      recoveryData.SecondRecoveryAmtUnits,
      recoveryData.SecondRecoveryDesc
    );
    const thirdRecovery = buildRemarks(
      recoveryData.ThirdRecoveryAmt,
      recoveryData.ThirdRecoveryAmtUnits,
      recoveryData.ThirdRecoveryDesc
    );
    const fourthRecovery = buildRemarks(
      recoveryData.FourthRecoveryAmt,
      recoveryData.FourthRecoveryAmtUnits,
      recoveryData.FourthRecoveryDesc
    );
    const fifthRecovery = buildRemarks(
      recoveryData.FifthRecoveryAmt,
      recoveryData.FifthRecoveryAmtUnits,
      recoveryData.FifthRecoveryDesc
    );
    const sixthRecovery = buildRemarks(
      recoveryData.SixthRecoveryAmt,
      recoveryData.SixthRecoveryAmtUnits,
      recoveryData.SixthRecoveryDesc
    );

    recoveryRemarksText = recoveryRemarksText.concat(
      firstRecovery,
      secondRecovery,
      thirdRecovery,
      fourthRecovery,
      fifthRecovery,
      sixthRecovery
    );

    return stringValidation(recoveryRemarksText);
  };

  const formatIncrementalTrace = (data?: DatalakeIncrementalData[] | null) => {
    if (data === undefined || data === null || !data.length) {
      return data;
    }

    const mappedData = data.sort((a, b) => {
      return (a.ChartNo ?? 0) - (b.ChartNo ?? 0);
    });

    const incrementalDataTrace: Data[] = [];
    const trace: Data = {
      type: "scatter",
      mode: "lines+markers",
      marker: { color: "rgba(0, 176, 255, 1)" },
      line: { color: "rgba(255, 255, 255, 0.7)" },
      x: [],
      y: [],
      hoverinfo: "none",
    };
    mappedData.forEach((incrementalData) => {
      (trace.x as Datum[]).push(parseNumberData(incrementalData.Time ?? 0));
      (trace.y as Datum[]).push(incrementalData.Pressure ?? 0);
    });

    incrementalDataTrace.push(trace);
    return incrementalDataTrace;
  };

  const formatHornerPlot = (data?: DatalakeHornerPlotData[] | null) => {
    if (data === undefined || data === null || !data.length) {
      return data;
    }
    //get unique shut in period
    const shutInPeriodList = [
      ...new Set(data.map((item) => item.ShutInPeriod)),
    ];

    const hornerPlotDataTrace: Data[] = [];

    //create traces for each shut in period
    shutInPeriodList.forEach((shutInPeriod, index) => {
      const trace: Data = {
        type: "scatter",
        mode: "markers",
        marker: { color: defaultTopColor[index] },
        x: [],
        y: [],
        hoverinfo: "none",
        name: `${formatNumberInOrdinal(shutInPeriod)} Shut-In`,
      };

      const dataByShutInPeriodList = data.filter(
        (hornerData) => hornerData.ShutInPeriod === shutInPeriod
      );

      const mappedData = dataByShutInPeriodList.sort((a, b) => {
        return (a.IncrementNo ?? 0) - (b.IncrementNo ?? 0);
      });

      mappedData.forEach((dataByShutInPeriod) => {
        (trace.x as Datum[]).push(dataByShutInPeriod.HornerTime ?? 0);
        (trace.y as Datum[]).push(dataByShutInPeriod.Pressure ?? 0);
      });

      hornerPlotDataTrace.push(trace);
    });

    return hornerPlotDataTrace;
  };

  const dataMapper = (data: DatalakePressureDataResponseBody) => {
    return {
      DocType: data.DocType,
      TestType: getTestType(data.DocType as TestType),
      DSTNo: data.DstNumber,
      TestName: createTestName(data.DocType as TestType, data.DstNumber),
      Formation: data.ReportedFormation ?? "",
      TgsLandingZone: stringValidation(data.TgsLandingZone ?? ""),
      Temperature: formatTemp(data.DstTemp),
      Top: formatDepthData(data.IntF),
      Bottom: formatDepthData(data.IntT),
      FirstFlow: formatPressureData(data.FirstFlowDuration, data.FirstFlowEnd),
      FirstFlowShutIn: formatPressureData(
        data.FirstShutinDuration,
        data.FirstShutinEnd
      ),
      SecondFlow: formatPressureData(
        data.SecondFlowDuration,
        data.SecondFlowEnd
      ),
      SecondFlowShutIn: formatPressureData(
        data.SecondShutinDuration,
        data.SecondShutinEnd
      ),
      RecoveryRemarks: formatRecoveryRemarks({
        FirstRecoveryAmt: data.FirstRecoveryAmt,
        FirstRecoveryAmtUnits: data.FirstRecoveryAmtUnits,
        FirstRecoveryDesc: data.FirstRecoveryDesc,
        SecondRecoveryAmt: data.SecondRecoveryAmt,
        SecondRecoveryAmtUnits: data.SecondRecoveryAmtUnits,
        SecondRecoveryDesc: data.SecondRecoveryDesc,
        ThirdRecoveryAmt: data.ThirdRecoveryAmt,
        ThirdRecoveryAmtUnits: data.ThirdRecoveryAmtUnits,
        ThirdRecoveryDesc: data.ThirdRecoveryDesc,
        FourthRecoveryAmt: data.FourthRecoveryAmt,
        FourthRecoveryAmtUnits: data.FourthRecoveryAmtUnits,
        FourthRecoveryDesc: data.FourthRecoveryDesc,
        FifthRecoveryAmt: data.FifthRecoveryAmt,
        FifthRecoveryAmtUnits: data.FifthRecoveryAmtUnits,
        FifthRecoveryDesc: data.FifthRecoveryDesc,
        SixthRecoveryAmt: data.SixthRecoveryAmt,
        SixthRecoveryAmtUnits: data.SixthRecoveryAmtUnits,
        SixthRecoveryDesc: data.SixthRecoveryDesc,
      }),
      ...("Incremental" in data &&
        data.DocType === PRESSURE_DATA_TEST_TYPES.DST_PLUS && {
          IncrementalChartTrace: formatIncrementalTrace(data.Incremental),
        }),
      ...("HornerPlot" in data &&
        data.DocType === PRESSURE_DATA_TEST_TYPES.DST_PLUS && {
          HornerPlotChartTrace: formatHornerPlot(data.HornerPlot),
        }),
    } as PressureDataWellPanel;
  };

  const buildPressureData = (rawData: DatalakePressureDataResponseBody[]) => {
    let parsedPressureData: PressureDataWellPanel[] = [];

    //Filter DST Plus
    const filteredDstDataPlusData = rawData.filter(
      (pressureData) =>
        pressureData.DocType === PRESSURE_DATA_TEST_TYPES.DST_PLUS
    );

    //Filter DST that doesn't have DST Plus data
    const filteredDstDataPlusNumberList = (
      clone(filteredDstDataPlusData) as DatalakePressureDataResponseBody[]
    ).map((dstPlusData) => dstPlusData.DstNumber);

    const filteredDstData = rawData.filter(
      (dstData) =>
        dstData.DocType === PRESSURE_DATA_TEST_TYPES.DST &&
        !filteredDstDataPlusNumberList.includes(dstData.DstNumber)
    );

    //map data
    const mappedDSTPlustData = (
      clone(filteredDstDataPlusData) as DatalakePressureDataResponseBody[]
    ).map((dstPlusData) => {
      return dataMapper(dstPlusData);
    });

    const mapDstData = (
      clone(filteredDstData) as DatalakePressureDataResponseBody[]
    ).map((dstData) => {
      return dataMapper(dstData);
    });

    parsedPressureData = [...mappedDSTPlustData, ...mapDstData];

    return sortPanelData(parsedPressureData, "TestName");
  };

  return {
    buildPressureData,
  };
};

export default usePressureData;
