import { FC, useEffect, useMemo, useState } from "react";

import { Box } from "@mui/material";

import {
  WellEconomicsResponse,
  WellEconomicsStore,
} from "../../../../types/panels/wellPanel/wellEconomicsType";

import { noData } from "../../../../constants/constants";
import * as econ from "../../../../constants/panels/wellPanel/wellEconomics";

import useWellEconomicsUoM from "../../../../customHooks/panels/wellPanel/useWellEconomicsUoM";

import { formatWellEconomicsOutputValue } from "../../../../utils/panel/wellPanel/economics";

import Loading from "../../../common/Loading";
import CrossArrowIcon from "../../../common/icons/CrossArrowIcon";
import CurrencyExchangeIcon from "../../../common/icons/CurrencyExchangeIcon";
import HourGlassIcon from "../../../common/icons/HourGlassIcon";
import TimelineIcon from "../../../common/icons/TimelineIcon";

interface WellEconomicsOutputProp {
  wellEconomicsData?: WellEconomicsStore;
  isLoading: boolean;
  setUoMUnit?: boolean;
}

const WellEconomicsOutput: FC<WellEconomicsOutputProp> = ({
  wellEconomicsData,
  isLoading,
  setUoMUnit,
}) => {
  const [economics, setEconomics] = useState<WellEconomicsResponse>();
  const { formatWellEconomicsUnits } = useWellEconomicsUoM();

  const outputValues = useMemo(() => {
    return {
      irr: economics?.Outputs?.[econ.WELL_ECONOMICS_INTERNAL_RATE_RETURN.key]
        ? `${formatWellEconomicsOutputValue(
            economics.Outputs[econ.WELL_ECONOMICS_INTERNAL_RATE_RETURN.key],
            1
          )}${econ.WELL_ECONOMICS_INTERNAL_RATE_RETURN.unit}`
        : noData,
      breakevenPrice:
        economics?.Outputs?.[econ.WELL_ECONOMICS_BREAKEVEN_PRICE.key] &&
        formatWellEconomicsOutputValue(
          economics?.Outputs?.[econ.WELL_ECONOMICS_BREAKEVEN_PRICE.key],
          1
        ) !== noData
          ? `${formatWellEconomicsOutputValue(
              economics?.Outputs?.[econ.WELL_ECONOMICS_BREAKEVEN_PRICE.key],
              1
            )} ${
              setUoMUnit
                ? formatWellEconomicsUnits(
                    econ.WELL_ECONOMICS_BREAKEVEN_PRICE.key,
                    econ.WELL_ECONOMICS_BREAKEVEN_PRICE.unit,
                    wellEconomicsData?.defaultValues.TargetUom
                  )
                : econ.WELL_ECONOMICS_BREAKEVEN_PRICE.unit
            }`
          : noData,
      npr: economics?.Outputs?.[econ.WELL_ECONOMICS_NET_PRESENT_VALUE.key]
        ? `${
            econ.WELL_ECONOMICS_NET_PRESENT_VALUE.unit
          } ${formatWellEconomicsOutputValue(
            economics?.Outputs?.[econ.WELL_ECONOMICS_NET_PRESENT_VALUE.key],
            0,
            true
          )}`
        : noData,
      payout: economics?.Outputs?.[econ.WELL_ECONOMICS_PAYOUT.key]
        ? `${formatWellEconomicsOutputValue(
            economics?.Outputs?.[econ.WELL_ECONOMICS_PAYOUT.key],
            1
          )} ${econ.WELL_ECONOMICS_PAYOUT.unit}`
        : noData,
    };
  }, [economics]);

  useEffect(() => {
    if (wellEconomicsData) {
      if (wellEconomicsData.recalculatedValues) {
        setEconomics(wellEconomicsData.recalculatedValues);
      } else {
        setEconomics(wellEconomicsData.defaultValues);
      }
    }
  }, [wellEconomicsData]);

  return (
    <Box
      className={`well-economics-output-container ${
        !isLoading ? "disabled-container" : ""
      } ${
        wellEconomicsData?.recalculatedValues ? "recalculate-highlight" : ""
      } `}
    >
      <div className="output-item InternalRateOfReturn">
        <TimelineIcon />
        <div className="output-text">
          <p className="econ-label">
            {econ.WELL_ECONOMICS_INTERNAL_RATE_RETURN.label}
          </p>
          <p className="econ-value">
            {isLoading ? (
              <Loading className="loading-spinner" size={15} />
            ) : (
              <>{outputValues.irr}</>
            )}
          </p>
        </div>
      </div>
      <div className="output-item BreakEvenPrice">
        <CrossArrowIcon />

        <div className="output-text">
          <p className="econ-label">
            {econ.WELL_ECONOMICS_BREAKEVEN_PRICE.label}
          </p>
          <p className="econ-value">
            {isLoading ? (
              <Loading className="loading-spinner" size={15} />
            ) : (
              <>{outputValues.breakevenPrice}</>
            )}
          </p>
        </div>
      </div>
      <div className="output-item NetPresentValue">
        <CurrencyExchangeIcon />
        <div className="output-text">
          <p className="econ-label">
            {econ.WELL_ECONOMICS_NET_PRESENT_VALUE.label}
          </p>
          <p className="econ-value">
            {isLoading ? (
              <Loading className="loading-spinner" size={15} />
            ) : (
              <>{outputValues.npr}</>
            )}
          </p>
        </div>
      </div>
      <div className="output-item Payout">
        <HourGlassIcon />
        <div className="output-text">
          <p className="econ-label">{econ.WELL_ECONOMICS_PAYOUT.label}</p>
          <p className="econ-value">
            {isLoading ? (
              <Loading className="loading-spinner" size={15} />
            ) : (
              <>{outputValues.payout}</>
            )}
          </p>
        </div>
      </div>
    </Box>
  );
};

export default WellEconomicsOutput;
