import { useCallback, useEffect, useMemo, useState } from "react";

import { ChartModeView } from "../../types/charts/chartType/chartType";
import { DefaultLayout } from "../../types/modularity/layout";
import { Modules } from "../../types/modularity/modules";

import { CHART_MODE_VIEW } from "../../constants/charts/chartModeView";
import { MAX_WELLS_COUNT_ASSET_ECONOMICS } from "../../constants/charts/charts";
import {
  ASSET_ECONOMICS_COLLAPSED_HEIGHT_LAYOUT,
  ASSET_ECONOMICS_EXPANDED_HEIGHT_LAYOUT,
} from "../../constants/economics/assetEconomics";

import useAlertPopupStore from "../../store/map/alert/alertStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useModularityStore from "../../store/modularity/modularityStore";

import { clone } from "../../utils/helper";

const useWellEconomicsAggregation = (
  chartId: string,
  chartMode: ChartModeView
) => {
  const [triggerWarning, setTriggerWarning] = useState<string>();
  const [isExpandedAccordion, setIsExpandedAccordion] = useState(false);

  const selectedWellUwis = useMapSelectionStore(
    (state) => state.selectedWellUwis
  );

  const modules = useModularityStore((state) => state.modules);
  const chartDefaultLayout = useModularityStore(
    (state) => state.chartDefaultLayout
  );
  const updateModuleDataByKey = useModularityStore(
    (state) => state.updateModuleDataByKey
  );
  const updateChartDefaultLayoutByKey = useModularityStore(
    (state) => state.updateChartDefaultLayoutByKey
  );

  const fullScreenChartId = useModularityStore(
    (state) => state.fullScreenChartId
  );

  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  const warningMessage = useMemo(() => {
    if (!selectedWellUwis.length) {
      return "Select Wells to view Well Economics data";
    }
    if (selectedWellUwis.length >= MAX_WELLS_COUNT_ASSET_ECONOMICS) {
      updateAlertState({
        severity: "warning",
        message:
          "Some charts not available for analysis since too many wells selected.",
      });

      return "Select max 200k wells";
    }
    if (triggerWarning !== "") return triggerWarning;

    setTriggerWarning(undefined);
    return undefined;
  }, [selectedWellUwis, triggerWarning]);

  const getModule = useCallback(() => {
    const chartModule: Modules | undefined = (clone(modules) as Modules[]).find(
      (module) => module.module === chartId
    );

    return chartModule;
  }, [modules]);

  //to update once fullscreen is implemented for asset economics
  const getChartLayout = useCallback(() => {
    const chartLayout: DefaultLayout | undefined = (
      clone(chartDefaultLayout) as DefaultLayout[]
    ).find((layout) => layout.i === chartId);

    return chartLayout;
  }, [chartDefaultLayout]);

  const updateEconomicLayoutHeight = (expandSectionHeight: boolean) => {
    const economicLayout = getChartLayout();

    if (economicLayout) {
      if (expandSectionHeight) {
        const updatedChartLayout = {
          ...economicLayout,
          h: ASSET_ECONOMICS_EXPANDED_HEIGHT_LAYOUT,
          minH: ASSET_ECONOMICS_EXPANDED_HEIGHT_LAYOUT,
        };

        updateChartDefaultLayoutByKey(chartId, updatedChartLayout);
      } else {
        const updatedChartLayout = {
          ...economicLayout,
          h: ASSET_ECONOMICS_COLLAPSED_HEIGHT_LAYOUT,
          minH: ASSET_ECONOMICS_COLLAPSED_HEIGHT_LAYOUT,
        };

        updateChartDefaultLayoutByKey(chartId, updatedChartLayout);
      }
    }
  };

  const handleOnExpandDetails = (expanded: boolean) => {
    const ecconomicModule = getModule();

    setIsExpandedAccordion(expanded);

    if (ecconomicModule) {
      const updatedModuleData = {
        ...ecconomicModule,
        isExpandedAccordion: expanded,
      };

      updateModuleDataByKey(chartId, updatedModuleData);
    }

    updateEconomicLayoutHeight(expanded);
  };

  const isActiveFullScreenView = useMemo(() => {
    return Boolean(chartId === fullScreenChartId);
  }, [fullScreenChartId]);

  const handleSectionHeightUpdate = (isLoading: boolean) => {
    const currentLayout = getChartLayout();
    //set height to collapsed height if height is in expanded view
    if (
      currentLayout?.h !== ASSET_ECONOMICS_COLLAPSED_HEIGHT_LAYOUT &&
      (isLoading || warningMessage)
    ) {
      updateEconomicLayoutHeight(false);
      setIsExpandedAccordion(false);
    } else {
      const currentModule = getModule();
      //if isExpanded is true but layout height is not set for expanded height
      //else if isExpanded is false but layout height is not set collapsed height
      if (
        currentModule?.isExpandedAccordion &&
        currentLayout?.h !== ASSET_ECONOMICS_EXPANDED_HEIGHT_LAYOUT
      ) {
        updateEconomicLayoutHeight(true);
        setIsExpandedAccordion(true);
      } else if (
        !currentModule?.isExpandedAccordion &&
        currentLayout?.h !== ASSET_ECONOMICS_COLLAPSED_HEIGHT_LAYOUT
      ) {
        updateEconomicLayoutHeight(false);
        setIsExpandedAccordion(false);
      }
    }
  };

  //triggers only on first open of fullscreen
  useEffect(() => {
    if (
      isActiveFullScreenView ||
      chartMode === CHART_MODE_VIEW.FULLSCREEN_ANALYSIS
    ) {
      setIsExpandedAccordion(true);
    } else {
      setIsExpandedAccordion(false);
    }
  }, []);

  return {
    warningMessage,
    isExpandedAccordion,
    setTriggerWarning,
    handleOnExpandDetails,
    handleSectionHeightUpdate,
  };
};

export default useWellEconomicsAggregation;
