import { useCallback } from "react";

import { ChartType } from "../../types/charts/chartType/chartType";

import {
  CHART_TYPES,
  MAX_WELLS_COUNT_ASSET_ECONOMICS,
} from "../../constants/charts/charts";

import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";

const useChartReference = () => {
  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const selectedWellIdsKeys = useMapSelectionStore(
    (state) => state.selectedWellIdsKeys
  );

  const selectedWellUwis = useMapSelectionStore(
    (state) => state.selectedWellUwis
  );

  const checkIDsReference = useCallback(
    (chartType: ChartType) => {
      switch (chartType) {
        case CHART_TYPES.PRODUCTION_PLOT || CHART_TYPES.TYPE_CURVE:
          return Boolean(selectedWellIdsKeys.length);
        case CHART_TYPES.ASSET_ECONOMICS:
          return Boolean(
            selectedWellUwis.length &&
              selectedWellUwis.length < MAX_WELLS_COUNT_ASSET_ECONOMICS
          );
        default:
          return Boolean(selectedGridDataKeys.length);
      }
    },
    [selectedWellIdsKeys, selectedGridDataKeys, selectedWellUwis]
  );

  return { checkIDsReference };
};

export default useChartReference;
