import { MouseEvent, memo, useEffect, useState } from "react";

import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";

import { ThemeProvider } from "styled-components";

import { IChartTypeInfo } from "../../../types/charts/chartType/chartType";
import { DefaultLayout } from "../../../types/modularity/layout";
import { Modules } from "../../../types/modularity/modules";
import { AnalysisChartsPanelHeaderProps } from "../../../types/panels/analysisChartsPanel/analysisChartsPanel";

import { CHART_STATUS, CHART_TYPES } from "../../../constants/charts/charts";

import useChartStore from "../../../store/chart/chartStore";
import useMapSelectionStore from "../../../store/map/selection/mapSelectionStore";
import useModularityStore from "../../../store/modularity/modularityStore";
import usePanelsStore from "../../../store/panels/panelsStore";

import useChartReference from "../../../customHooks/charts/useChartReference";

import { getNumberWithComma } from "../../../utils/formatters/numberFormatter";

import { darkTheme } from "../../../themes";
import { clone } from "../../../utils";
import AnalysisChartToggle from "./AnalysisChartToggle";
import AnalysisChartToolbarControls from "./AnalysisChartToolbarControls";
import AnalysisChartsPanelCount from "./AnalysisChartsPanelCount";

const AnalysisChartsPanelHeader = ({
  chartMode,
}: AnalysisChartsPanelHeaderProps) => {
  const modules = useModularityStore((state) => state.modules);
  const updateModuleDataByKey = useModularityStore(
    (state) => state.updateModuleDataByKey
  );
  const updateModules = useModularityStore((state) => state.updateModules);
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const chartFullScreenLayout = useModularityStore(
    (state) => state.chartFullScreenLayout
  );
  const updateChartFullScreenLayout = useModularityStore(
    (state) => state.updateChartFullScreenLayout
  );
  const chartDefaultLayout = useModularityStore(
    (state) => state.chartDefaultLayout
  );
  const updateChartDefaultLayout = useModularityStore(
    (state) => state.updateChartDefaultLayout
  );
  const addChartFullScreenLayout = useModularityStore(
    (state) => state.addChartFullScreenLayout
  );
  const addChartDefaultLayout = useModularityStore(
    (state) => state.addChartDefaultLayout
  );
  const chartData = useChartStore((state) => state.chartData);
  const updateChartDataByKey = useChartStore(
    (state) => state.updateChartDataByKey
  );
  const removeChartDataByKey = useChartStore(
    (state) => state.removeChartDataByKey
  );
  const toggleAnalysisPanel = usePanelsStore(
    (state) => state.toggleAnalysisPanel
  );
  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );
  const { checkIDsReference } = useChartReference();

  const updateChartLayout = (key: string) => {
    const copiedChartFullScreenLayout: DefaultLayout[] = clone(
      chartFullScreenLayout
    );
    const filteredChartFullScreenLayout: DefaultLayout[] =
      copiedChartFullScreenLayout.filter((chart: DefaultLayout) => {
        return chart.i !== key;
      });
    updateChartFullScreenLayout(filteredChartFullScreenLayout);

    const copiedChartDefaultLayout: DefaultLayout[] = clone(chartDefaultLayout);
    const filteredChartDefaultLayout: DefaultLayout[] =
      copiedChartDefaultLayout.filter((chart: DefaultLayout) => {
        return chart.i !== key;
      });

    updateChartDefaultLayout(filteredChartDefaultLayout);
  };

  const controlChart = (key: string, toggle: boolean) => {
    const copiedChartData: IChartTypeInfo[] = clone(chartData);
    const copiedModules: Modules[] = clone(modules);

    if (toggle) {
      const modifiedChartData: IChartTypeInfo[] = copiedChartData.map(
        (chart: IChartTypeInfo) => {
          if (chart.chartId === key) {
            chart.status = CHART_STATUS.EXPANDED;

            if (checkIDsReference(chart.chartType)) {
              if (chart.objectType !== CHART_TYPES.ASSET_ECONOMICS) {
                chart.chartData = [];
              }
            }
          }
          return chart;
        }
      );
      const updatedChartData: IChartTypeInfo | undefined =
        modifiedChartData.find((data) => data.chartId === key);

      if (updatedChartData) {
        updateChartDataByKey(key, updatedChartData);
      }

      const chartModule = copiedModules.find((module) => module.module === key);

      if (chartModule) {
        chartModule.isExpanded = true;
        chartModule.refetch = !checkIDsReference(chartModule.chartType);

        updateModuleDataByKey(key, chartModule);
      }

      const findChart: IChartTypeInfo | undefined = chartData.find(
        (chart) => chart.chartId === key
      );
      if (findChart) {
        addChartDefaultLayout(findChart.chartId, findChart.chartType);
        addChartFullScreenLayout(findChart.chartId, findChart.chartType);
      }
    } else {
      const modifiedChartData = copiedChartData.map((chart) => {
        if (chart.chartId === key) {
          chart.status = CHART_STATUS.COLLAPSED;
          if (checkIDsReference(chart.chartType)) {
            if (chart.objectType !== CHART_TYPES.ASSET_ECONOMICS) {
              chart.chartData = [];
            }
          }
        }
        return chart;
      });
      const updatedChartData: IChartTypeInfo | undefined =
        modifiedChartData.find((data) => data.chartId === key);

      if (updatedChartData) {
        updateChartDataByKey(key, updatedChartData);
      }

      updateChartLayout(key);

      const chartModule = copiedModules.find((module) => module.module === key);

      if (chartModule) {
        chartModule.isExpanded = false;

        updateModuleDataByKey(key, chartModule);
      }
    }
  };

  const closeChart = (key: string) => {
    removeChartDataByKey(key);

    updateChartLayout(key);

    const filteredModules: Modules[] = (clone(modules) as Modules[]).filter(
      (chart: any) => {
        return chart.module !== key;
      }
    );

    if (!filteredModules.length) toggleAnalysisPanel(false);
    updateModules(filteredModules);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (chartData.length === 0) {
      setAnchorEl(null);
    }
  }, [chartData]);

  const open = Boolean(anchorEl);

  return (
    <>
      <div className="analysis-title-header">
        <div className="analysis-menu-container">
          <AnalysisChartToggle />
        </div>

        <div className="chart-list">
          <div className="wells-chart-count-container">
            <AnalysisChartsPanelCount
              title="WELLS SELECTED"
              value={getNumberWithComma(selectedGridDataKeys?.length ?? 0)}
              className="selected-analysis"
            />

            <Divider orientation="horizontal" />

            <AnalysisChartsPanelCount
              title="CHARTS"
              value={modules.length}
              className="chart-count"
            />
          </div>
          <div className="analysis-menu-icon">
            <IconButton onClick={handleClick}>
              <ExpandMoreIcon fontSize="small" />
            </IconButton>
          </div>
          <ThemeProvider theme={darkTheme}>
            <div className="chart-title-menu">
              <Menu
                className="chart-title-menu"
                elevation={0}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <List>
                  {modules.map((chart) => (
                    <MenuItem
                      className="chart-title-menu-item"
                      key={chart.module}
                    >
                      <ListItem
                        className="chart-title-list-item"
                        disableGutters={true}
                      >
                        <Switch
                          edge="end"
                          onChange={(e) =>
                            controlChart(chart.module, e.target.checked)
                          }
                          defaultChecked={chart.isExpanded}
                        />
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography>
                              {chart.title !== ""
                                ? chart.title
                                : chart.chartType + "_" + chart.module}
                            </Typography>
                          }
                        />
                        <IconButton onClick={() => closeChart(chart.module)}>
                          <CloseIcon />
                        </IconButton>
                      </ListItem>
                    </MenuItem>
                  ))}
                </List>
              </Menu>
            </div>
          </ThemeProvider>
        </div>
        <div className="full-screen-icon">
          <AnalysisChartToolbarControls chartMode={chartMode} />
        </div>
      </div>
    </>
  );
};

export default memo(AnalysisChartsPanelHeader);
