import { MouseEvent, useState } from "react";

import {
  ChartType,
  IChartTypeInfo,
} from "../../types/charts/chartType/chartType";

import {
  CHART_GROUP_BY_TYPE,
  CHART_SCALING_TYPE,
  CHART_STATUS,
  CHART_TYPES,
} from "../../constants/charts/charts";
import { DEFAULT_PRODUCTION_Y_FIELDS } from "../../constants/charts/productionPlotDataFields";

import useChartStore from "../../store/chart/chartStore";
import useModularityStore from "../../store/modularity/modularityStore";
import usePanelsStore from "../../store/panels/panelsStore";

import { generateId } from "../../utils/common/generateId";

import useRecordType from "../common/useRecordType";
import useChartReference from "./useChartReference";
import useScatterPlotChartReset from "./useScatterPlotChartReset";

const useAnalysisChartToggle = () => {
  const [modulesMenu, setModulesMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const addModule = useModularityStore((state) => state.addModule);
  const addChartFullScreenLayout = useModularityStore(
    (state) => state.addChartFullScreenLayout
  );
  const addChartDefaultLayout = useModularityStore(
    (state) => state.addChartDefaultLayout
  );
  const addChartData = useChartStore((state) => state.addChartData);
  const toggleAnalysisPanel = usePanelsStore(
    (state) => state.toggleAnalysisPanel
  );
  const { getScatterPlotDefault } = useScatterPlotChartReset({});
  const { searchedRecordType } = useRecordType();
  const { checkIDsReference } = useChartReference();

  const handleMenuOpen = (event: MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setModulesMenu(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setModulesMenu(false);
  };

  const getNewChartData = (id: string, type: ChartType) => {
    switch (type) {
      case CHART_TYPES.PRODUCTION_PLOT:
      case CHART_TYPES.TYPE_CURVE:
        const newChartData: IChartTypeInfo = {
          objectType: type,
          title: "",
          chartType: type,
          chartId: id,
          chartYAxisScaling: CHART_SCALING_TYPE.LINEAR,
          chartShowForecast:
            type === CHART_TYPES.PRODUCTION_PLOT ? true : false,
          chartShowDailyValues: false,
          chartDisplayedDataFields: DEFAULT_PRODUCTION_Y_FIELDS,
          chartData: [],
          groupBy: CHART_GROUP_BY_TYPE.DEFAULT,
          chartRange: undefined,
          status: CHART_STATUS.EXPANDED,
          chartShowByNormalizedLateralLength: false,
        };

        return newChartData;
      case CHART_TYPES.SCATTER_PLOT:
        const defaultValues = getScatterPlotDefault(searchedRecordType);

        if (defaultValues) {
          const newScatterPlotChartData: IChartTypeInfo = {
            objectType: type,
            title: "",
            chartType: type,
            chartId: id,
            chartData: [],
            chartRange: undefined,
            status: CHART_STATUS.EXPANDED,
            chartXAxisDataFields: defaultValues.xAxis,
            chartYAxisDataFields: defaultValues.yAxis,
            chartAxis: defaultValues.axis,
            chartColorBy: defaultValues.colorBy,
          };
          return newScatterPlotChartData;
        }
        break;
      case CHART_TYPES.ASSET_ECONOMICS:
        const newAssetEconomicsData: IChartTypeInfo = {
          objectType: type,
          title: "",
          chartType: type,
          chartId: id,
          status: CHART_STATUS.EXPANDED,
        };

        return newAssetEconomicsData;

      case CHART_TYPES.CASH_FLOW:
        const newCashFlowChartData: IChartTypeInfo = {
          objectType: type,
          title: "",
          chartType: type,
          chartId: id,
          chartData: [],
          chartRange: undefined,
          status: CHART_STATUS.EXPANDED,
        };

        return newCashFlowChartData;
      default:
        break;
    }
  };

  const handleSelectItem = (type: ChartType) => {
    toggleAnalysisPanel(true);
    const id = generateId(5);
    addModule(id, type, "", checkIDsReference(type));
    addChartDefaultLayout(id, type);

    addChartFullScreenLayout(id, type);

    const newChartData = getNewChartData(id, type);
    if (newChartData) addChartData(newChartData);
  };

  return {
    open,
    anchorEl,
    modulesMenu,
    handleSelectItem,
    handleMenuOpen,
    handleMenuClose,
  };
};

export default useAnalysisChartToggle;
