import { FC, useEffect, useState } from "react";
import Plot from "react-plotly.js";

import { Data, PlotRelayoutEvent } from "plotly.js";

import { ChartRange } from "../../../../types/charts/chartType/chartType";

import {
  config,
  fontLayout,
  generalLayout,
  marginLayout,
  xAxisLayout,
  yAxisLayout,
} from "../../../../utils/charts/layouts/IncrementalLayout";

import WellPanelAccordion from "../../wellPanel/common/WellPanelAccordion";

interface IncerementalChartProps {
  trace: Data[];
}

const IncerementalChart: FC<IncerementalChartProps> = ({ trace }) => {
  const [expanded, setExpanded] = useState(false);
  const [plotlyLayout, setPlotlyLayout] =
    useState<Readonly<PlotRelayoutEvent>>();
  const [range, setRange] = useState<ChartRange>();

  const onChartRelayout = (figure: any) => {
    if (figure?.autosize) {
      //on initialization, first value of the callBack is just {autosize: true} so we should negate the call
      return;
    }
    setPlotlyLayout(figure);
  };

  useEffect(() => {
    //Triggers when reLayout throws a callback [NOTE: Even on new generation, it triggers]
    const layout = plotlyLayout;
    let layoutCount = 0;
    //Check if layout contains the right axis values so we count the length, length should be more than 1. [Expected: 4, 2 items per axis]
    if (layout) {
      layoutCount = Object.keys(layout).length;
    }
    let xRangeVal: undefined[] | number[] = [undefined, undefined];
    let yRangeVal: undefined[] | number[] = [undefined, undefined];

    if (layoutCount > 1) {
      const xAxisLower = layout?.["xaxis.range[0]"];
      const xAxisUpper = layout?.["xaxis.range[1]"];
      const yAxisLower = layout?.["yaxis.range[0]"];
      const yAxisUpper = layout?.["yaxis.range[1]"];
      let xLegend = layout?.legend?.x;
      let yLegend = layout?.legend?.y;

      if (xAxisLower && xAxisUpper) {
        xRangeVal = [xAxisLower, xAxisUpper];
      } else {
        if (range?.xRange) {
          xRangeVal = range.xRange;
        }
      }

      if (yAxisLower && yAxisUpper) {
        yRangeVal = [yAxisLower, yAxisUpper];
      } else {
        if (range?.yRange) {
          yRangeVal = range.yRange;
        }
      }

      if (!xLegend && range?.xLegend) {
        xLegend = range.xLegend;
      }
      if (!yLegend && range?.yLegend) {
        yLegend = range?.yLegend;
      }

      setRange({
        xLegend: xLegend,
        yLegend: yLegend,
        xRange: xRangeVal,
        yRange: yRangeVal,
        autorange: true,
      });
    }
  }, [plotlyLayout]);

  const handleResetChart = () => {
    const range: ChartRange = {
      xLegend: -0.1,
      yLegend: -0.7,
      autorange: true,
      yRange: [undefined, undefined],
      xRange: [undefined, undefined],
    };
    setRange(range);
  };

  useEffect(() => {
    //default to expand when switching dst
    setExpanded(true);
  }, [trace]);

  return (
    <WellPanelAccordion
      expanded={expanded}
      header={"INCREMENTAL SHUT-IN"}
      onChange={() => {
        setExpanded((prevState) => !prevState);
      }}
      isLoading={false}
      className="chart"
    >
      <div
        className="well-panel-chart-container dst-chart"
        data-testid="dst-panel-incremental-chart"
      >
        <Plot
          onDoubleClick={() => handleResetChart()}
          className="plot-chart"
          useResizeHandler={true}
          data={trace}
          config={config}
          layout={{
            ...generalLayout(),
            margin: {
              ...marginLayout(),
            },
            font: fontLayout,
            xaxis: {
              ...xAxisLayout(range),
              gridcolor: "rgba(117, 117, 117, 0.4)",
            },
            yaxis: {
              ...yAxisLayout(range),
              gridcolor: "rgba(117, 117, 117, 0.4)",
            },
          }}
          onRelayout={(figure) => {
            onChartRelayout(figure);
          }}
        />
      </div>
    </WellPanelAccordion>
  );
};

export default IncerementalChart;
