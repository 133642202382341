import { FC, SVGProps } from "react";

interface DoubleArrowIconProp extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const DoubleArrowIcon: FC<DoubleArrowIconProp> = ({
  width = "20",
  height = "20",
  fill = "white",
  pathFill = "white",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.979 10L4.1665 13.8333L5.33317 15L10.3332 10L5.33317 5L4.1665 6.16667L7.979 10ZM13.479 10L9.6665 13.8333L10.8332 15L15.8332 10L10.8332 5L9.6665 6.16667L13.479 10Z"
        fill={pathFill}
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default DoubleArrowIcon;
